import { memo, useEffect } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { displayNameSelector, tokenSelector } from "selectors/userSelectors";
import { RootState } from "store";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
import { userSlice } from "reducers/userSlice";
import { clientSelector } from "selectors/clientSelectors";
import jwt_decode from "jwt-decode";
import { LightenDarkenColor } from "lighten-darken-color";

const NavLink = styled(Nav.Link)`
  line-height: 0.9rem;
  margin-left: 1em;
  color: white !important;
  font-weight: 500;
  font-size: 0.9rem;
`;

const RoundedNavLink = styled(NavLink)<{ $primaryColorHex: string }>`
  background: white;
  color: ${(props) => props.$primaryColorHex} !important;
  border-radius: 7px !important;
  padding: 0.5em 1em 0.5em 0.75em !important;
  @media (max-width: 450px) {
    padding-left: 0.75em !important;
    padding-right: 0.75em !important;
  }
  :hover {
    color: ${(props) =>
      LightenDarkenColor(props.$primaryColorHex, -50)} !important;
  }
`;

const DropDownWelcomeToggle = styled(Dropdown.Toggle)<{ $primaryColorHex: string }>`
  --bs-btn-bg: ${(props) => props.$primaryColorHex};
  --bs-btn-hover-bg: ${(props) => props.$primaryColorHex};
  --bs-btn-active-bg: ${(props) => props.$primaryColorHex};
  --bs-btn-disabled-bg: ${(props) => props.$primaryColorHex};
  background: ${(props) => props.$primaryColorHex};
  border: none;  
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 0.9rem;
  margin-top: 2px;
`;

const DropDownItemCenter = styled(Dropdown.Item)<{ $primaryColorHex: string }>`
  --bs-dropdown-link-active-bg: ${(props) => props.$primaryColorHex};
  --bs-dropdown-link-hover-color: white;
  --bs-dropdown-link-hover-bg: ${(props) => props.$primaryColorHex};  
  text-align: center;
`;

const Session = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));
  const jwt = useAppSelector((state: RootState) => tokenSelector(state));

  const displayName = useAppSelector((state: RootState) =>
    displayNameSelector(state)
  );

  const onCreateAccountClick = () => {
    dispatch(uiSlice.actions.showSignUpModal(true));
  };

  const onRedirectButtonClick = () => {
    window.open(client?.settings?.theme?.redirectUrl, "_blank");  
  };

  const onSignInClick = () => {
    dispatch(uiSlice.actions.showSignInModal(true));
  };

  const onSignOutClick = () => {
    dispatch(userSlice.actions.signOut());
  };

  const onPasswordResetClick = () => {
    dispatch(uiSlice.actions.showPasswordResetModal(true));
  };

  const onBankDetailsUpdateClick = () => {
    dispatch(uiSlice.actions.showBankAccountModal(true));
  }

  const onContactUsClick = () => {
    dispatch(uiSlice.actions.showContactUsModal(true));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (jwt) {
        const decodedToken: any = jwt_decode(jwt);
        const currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          dispatch(userSlice.actions.signOut());
        }
      }
    }, 30000);
    return () => clearTimeout(timer);
  }, [dispatch, jwt]);

  const enableBankAccountConfirmation = client?.settings?.workflow?.enableBankAccountConfirmation;

  return (
    <Nav>
      {jwt && (
        <>
          {/* <Username>Welcome {displayName ?? "Unknown User"}</Username> */}
          <Dropdown>
            <DropDownWelcomeToggle variant="success" id="dropdown-basic" $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}>
              Welcome {displayName ?? "Unknown User"}
            </DropDownWelcomeToggle>

            <Dropdown.Menu align="end">
              {enableBankAccountConfirmation && (
                <>
                  <DropDownItemCenter onClick={onBankDetailsUpdateClick} $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}>Bank Details Update</DropDownItemCenter>              
                  <Dropdown.Divider />
                </>
              )}
              <DropDownItemCenter onClick={onPasswordResetClick} $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}>Password Reset</DropDownItemCenter>
              <Dropdown.Divider />
              <DropDownItemCenter onClick={onContactUsClick} $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}>Contact Us</DropDownItemCenter>
            </Dropdown.Menu>
          </Dropdown>
          <NavLink onClick={onSignOutClick}>Sign Out</NavLink>
        </>
      )}
      {!jwt && !client?.settings?.theme?.redirectMode && (
        <>
          <NavLink onClick={onSignInClick}>Sign In</NavLink>
          <RoundedNavLink
            $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}
            onClick={onCreateAccountClick}
          >
            Create Account
          </RoundedNavLink>
        </>
      )}
      {!jwt && client?.settings?.theme?.redirectMode && (
        <>
          <RoundedNavLink
            $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}
            onClick={onRedirectButtonClick}
          >
            {client?.settings?.theme?.redirectButtonText || "Register With Us"}
          </RoundedNavLink>
        </>
      )}
    </Nav>
  );
};

export default memo(Session);
