import { memo } from "react";
import * as moment from "moment-timezone";
import styled from "styled-components";
import { FONT_SIZES } from "../../constants";

const Date = styled.div<{ $fontSize: string }>`
  font-weight: 400;
  font-size: ${(props) => props.$fontSize};
`;

const Time = styled.div<{ $fontSize: string }>`
  font-weight: 400;
  font-size: ${(props) => props.$fontSize};
`;

const DateWrapper = styled.div`
  flex-direction: col;
`;

interface DateTileProps {
  date: moment.Moment;
  fontSize?: string;
}

let dateFormat = process.env["REACT_APP_DATE_FORMAT"] || 'DD/MM/YYYY'; 

const DateTile = (props: DateTileProps) => {
  const { date, fontSize = FONT_SIZES.Medium } = props;

  return (
    <DateWrapper>
      <Date $fontSize={fontSize}>{date.format(dateFormat)}</Date>
      <Time $fontSize={fontSize}>{date.format("HH:mm A")}</Time>
    </DateWrapper>
  );
};

export default memo(DateTile);
