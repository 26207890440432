import { FONT_SIZES } from "../../constants";
import { memo } from "react";
import styled from "styled-components";

interface CurrencyProps {
  amount: number;
  code?: string;
  showCode?: boolean;
  showCents?: boolean;
  fontSize?: string;
  fontWeight?: string;
  color?: string;  
}

const Wrapper = styled.div<{
  $fontSize: string;
  $color: string;
  $fontWeight: string;
}>`
  font-size: ${(props) => props.$fontSize};
  color: ${(props) => props.$color};
  font-weight: ${(props) => props.$fontWeight};
`;

const Currency = (props: CurrencyProps) => {
  const {
    amount,
    showCents,
    showCode,
    code,
    fontSize = FONT_SIZES.Medium,
    color = "#3D3D3D",
    fontWeight = "400",
  } = props;
  
  const renderCurrency = () => {
    let amountString = amount?.toString();

    if (!amountString) return "N/A";

    if (amountString.lastIndexOf(".") > 0) {
      amountString = amountString.substring(0, amountString.lastIndexOf("."));
    }

    if (amount >= 1000000) {
      return ((showCode ?? false) ? ((code ? code : "AUD") + " ") : "") + `$${amountString.substring(
        0,
        amountString.length - 6
      )}.${amountString.substring(
        amountString.length - 6,
        amountString.length - 5
      )}m`;
    }

    if (amount >= 1000) {
      return ((showCode ?? false) ? ((code ? code : "AUD") + " ") : "") + `$${amountString.substring(
        0,
        amountString.length - 3
      )}.${amountString.substring(
        amountString.length - 3,
        amountString.length - 2
      )}k`;
    }

    return ((showCode ?? false) ? ((code ? code : "AUD") + " $") : "$") + amount.toLocaleString("en-AU", {
      style: "decimal",
      minimumFractionDigits: showCents ? 2 : 0,
      maximumFractionDigits: showCents ? 6 : 0,
    });
  };

  return (
    <Wrapper $fontSize={fontSize} $color={color} $fontWeight={fontWeight}>
      {renderCurrency()}
    </Wrapper>
  );
};

Currency.defaultProps = {
  showCents: true,
};

export default memo(Currency);
