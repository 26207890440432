import InputError from "components/inputError/InputError";
import { ChangeEventHandler, memo } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;

const StyledCheckBox = styled(Form.Check)`
  margin-right: 10px;
`;

interface CheckBoxProps {
  children?: any;
  defaultChecked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isTouched?: boolean;
  errorMessage?: string | undefined;
  value?: string;
}

const CheckBox = (props: CheckBoxProps) => {
  const { children, defaultChecked, onChange, isTouched, errorMessage, value } =
    props;
  const showError = isTouched && errorMessage !== undefined;

  return (
    <>
      <Wrapper>
        <StyledCheckBox
          className="mr-2"
          type="checkbox"
          defaultChecked={defaultChecked === true}
          onChange={onChange}
          value={value}
        />
        {children}
      </Wrapper>
      <InputError visible={showError}>{props.errorMessage}</InputError>
    </>
  );
};

export default memo(CheckBox);
