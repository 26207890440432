import { memo, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { RootState } from "store";
import { signInModalVisibleSelector } from "../../selectors/uiSelectors";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
import { userSlice } from "reducers/userSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import Input from "components/input/Input";
import ModalNotice from "components/modalNotice/ModalNotice";
import { SignIn } from "types/signIn";
import {
  authenticatedUserSelector,
  signInErrorSelector,
  signingInSelector,
  signInSuccessSelector,
} from "selectors/userSelectors";
import { clientSelector } from "selectors/clientSelectors";

import Modal from "../modal/Modal";
import styled from "styled-components";
import FormError from "components/formError/FormError";

const LinkButton = styled(Button)`
  padding: 0 3px 2px;
  font-size: 0.9rem;
  font-weight: 500;
  @media (max-width: 520px) {
    font-size: 0.8rem;
    padding: 0 3px 2px;
  }
`;

const SignInModal = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));
  const user = useAppSelector((state: RootState) => authenticatedUserSelector(state));

  const signInError = useAppSelector((state: RootState) =>
    signInErrorSelector(state)
  );

  const signInModalVisible = useAppSelector((state: RootState) =>
    signInModalVisibleSelector(state)
  );

  const signingIn = useAppSelector((state: RootState) =>
    signingInSelector(state)
  );

  const signInSuccess = useAppSelector((state: RootState) =>
    signInSuccessSelector(state)
  );

  const schema = Yup.object({
    email: Yup.string().required("Email is a required field."),
    password: Yup.string().required("Pasword is a required field."),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: any) => {
      const signIn: SignIn = {
        ...values,
      };
      dispatch(userSlice.actions.signIn(signIn));
    },
  });

  useEffect(() => {
    if (signInModalVisible && signInSuccess) {
      formik.resetForm();
      dispatch(uiSlice.actions.showSignInModal(false));

      // // Fetch the status of the Account Fast if not Verified
      // if (client?.settings?.workflow?.enableOnlineAccountRegistrationForm && user?.verificationStatus !== 'Verified') {
      //   dispatch(userSlice.actions.loadOnlineAccountRegistrationStatus());
      // }
    }
  }, [dispatch, formik, signInSuccess, signInModalVisible, client, user]);

  const onCancel = () => {
    formik.resetForm();
    dispatch(uiSlice.actions.showSignInModal(false));
  };

  const resetClick = () => {
    formik.resetForm();
    dispatch(uiSlice.actions.showSignInModal(false));
    dispatch(uiSlice.actions.showPasswordResetModal(true));
  };

  const createAccountClick = () => {
    formik.resetForm();
    dispatch(uiSlice.actions.showSignInModal(false));
    dispatch(uiSlice.actions.showSignUpModal(true));
  };

  return (
    <Modal
      className="pb-3"
      title="Sign In"
      onCancel={onCancel}
      visible={signInModalVisible}
    >
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Container>
          <Form.Group className="mb-4" controlId="email">
            <Input
              type="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isTouched={formik.touched.email}
              errorMessage={formik.errors.email}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="password">
            <Input
              type="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              isTouched={formik.touched.password}
              errorMessage={formik.errors.password}
            />
          </Form.Group>
          <FormError message={signInError} />
          <PrimaryButton
            background={client?.settings?.theme?.primaryColorHex}
            type="submit"
            color="white"
            disabled={signingIn}
            loading={signingIn}
          >
            Sign In
          </PrimaryButton>
          <ModalNotice align="center" className="pt-3 pb-2">
            Trouble logging in?
            <LinkButton variant="link" onClick={resetClick}>
              Reset Password
            </LinkButton>
          </ModalNotice>
          <ModalNotice separatorColor="#e4e3e2" align="center" className="pt-2">
            Don't have an account?
            <LinkButton variant="link" onClick={createAccountClick}>
              Create Account Now
            </LinkButton>
          </ModalNotice>
        </Container>
      </Form>
    </Modal>
  );
};

export default memo(SignInModal);
