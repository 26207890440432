import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import {
  offersLoadingSelector,
  offersSelector,
} from "../../selectors/offerSelectors";
import { clientSelector } from "../../selectors/clientSelectors";
import { offerSlice } from "reducers/offerSlice";
import OffersGrid from "../../components/offersGrid/OffersGrid";
import styled from "styled-components";
import { tokenSelector } from "selectors/userSelectors";

const OffersGridWrapper = styled.div<{
  $primaryColorHex: string | undefined;
  $backgroundColorHex: string | undefined;
}>`
  padding: 1em;
  display: flex;
  flex: 1;
  background: linear-gradient(
    175deg,
    ${(props) => props.$primaryColorHex ?? "white"} 50%,
    ${(props) => props.$backgroundColorHex ?? "white"} 50%
  );
  @media (max-width: 450px) {
    padding: 0.5em;
    background: ${(props) => props.$primaryColorHex ?? "white"};
  }
`;

const OffersPage = () => {
  const dispatch = useAppDispatch();

  const jwt = useAppSelector((state: RootState) => tokenSelector(state));

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const offers = useAppSelector((state: RootState) => offersSelector(state));

  const offersLoading = useAppSelector((state: RootState) =>
    offersLoadingSelector(state)
  );

  useEffect(() => {
    dispatch(offerSlice.actions.loadOffers());
  }, [jwt, dispatch]);

  return (
    <OffersGridWrapper
      $primaryColorHex={client?.settings?.theme?.primaryColorHex || 'black'}
      $backgroundColorHex={client?.settings?.theme?.backgroundColorHex || 'black'}
    >
      <OffersGrid offers={offers} offersLoading={offersLoading} />
    </OffersGridWrapper>
  );
};

export default memo(OffersPage);
