import { memo } from "react";
import { Container, Navbar } from "react-bootstrap";
import styled from "styled-components";
import Session from "components/session/Session";
import VerificationHeader from "components/verificationHeader/VerificationHeader";

import { useAppSelector } from "hooks";
import { RootState } from "store";
import { clientSelector } from "selectors/clientSelectors";

const StyledNavbar = styled(Navbar)<{ $primaryColorHex: string }>`
  background: ${(props) => props.$primaryColorHex};
  padding: 1em 0.25em 0;
  color: white;

  > .container-fluid {
    align-items: end;
  }

  @media (max-width: 450px) {
    padding: 0.5em 0 0;
    > .container-fluid {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }

  @media (max-width: 660px) {
    > .container-fluid {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const Title = styled.div`
  > h1 {
    font-size: 1.7rem;
    text-transform: uppercase;
    margin: 0;
  }
  align-self: start;
  text-align: left;
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 10px;
  }

  @media (max-width: 660px) {
    width: 100%;
    > div {
      margin-bottom: 10px;
    }
  }
`;

const TopBar = () => {
  const client = useAppSelector((state: RootState) => clientSelector(state));

  return (
    <StyledNavbar $primaryColorHex={client?.settings?.theme?.primaryColorHex ?? "black"}>
      <Container fluid={true}>
        <Title>
          <h1>LIVE DEALS</h1>
          <VerificationHeader />
        </Title>
        <Session />
      </Container>
    </StyledNavbar>
  );
};

export default memo(TopBar);
