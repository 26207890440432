import { PayloadAction } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";
import {
  userSlice,
  registerUserSuccess,
  registerUserFailed,
  registerUserErrors,
  validateAddressSuccess,
  validateAddressFailed,
  signIn,
  signInSuccess,
  signInFailed,
  requestPasswordResetSuccess,
  changePasswordFailed,
  changePasswordSuccess,
  getAuthenticatedUserSuccess,
  getAuthenticatedUserFailed,
  getAuthenticatedUser,
  saveIdentityDataSuccess,
  saveIdentityDataFailed,
  resendMobileVerificationSuccess,
  resendMobileVerificationFailed,
  verifyMobileSuccess,
  verifyMobileFailed,
  requestPasswordResetFailed,
  loadSophisticatedApplicationLinkSuccess,
  loadSophisticatedApplicationLinkFailed,
  saveSophisticatedApplicationSuccess,
  saveSophisticatedApplicationFailed,
  saveBankAccountInformationSuccess,
  saveBankAccountInformationFailed,
  loadOnlineAccountRegistrationStatusFailed,
  loadOnlineAccountRegistrationStatusSuccess,
  saveIdentityErrors,
} from "reducers/userSlice";

import { put, takeLatest } from "redux-saga/effects";
import { HttpResponse } from "types/httpResponse";
import { RegisterUserResponse } from "types/registerUserResponse";
import { UserRegistration } from "types/userRegistration";
import { ValidateAddressResponse } from "types/validateAddressResponse";
import { GetPlaceDetailsResponse } from "types/getPlaceDetailsResponse";
import { SignIn } from "types/signIn";
import { RequestPasswordReset } from "types/requestPasswordReset";
import { ChangePassword } from "types/changePassword";
import { AuthenticatedUser } from "types/authenticatedUser";
import { SaveIdentityData } from "types/saveIdentityData";
import { VerifyMobile } from "types/verifyMobile";
import { GetSophisticatedApplicationLinkResponse } from "types/getSophisticatedApplicationLinkResponse";
import { SaveBankAccountInformation } from "types/saveBankAccountInformation";
import { GetOnlineAccountRegistrationStatusResponse } from "types/getOnlineAccountRegistrationStatusResponse";

import axios from "axios";


function* registerUserSaga(action: PayloadAction<UserRegistration>) {
  try {
    const createUserResponse: HttpResponse<RegisterUserResponse> =
      yield axios.post("/user", {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        password: action.payload.password,
        mobile: action.payload.mobile,
      });
    yield put(registerUserSuccess(createUserResponse.data.userId));
    yield put(
      signIn({
        email: action.payload.email,
        password: action.payload.password,
      })
    );
  } catch (error: any) {
    yield put(registerUserFailed(error.message));
    yield error.response.data.errors && put(registerUserErrors(error.response.data.errors));
  }
}

function* validateAddressSaga(action: PayloadAction<string>) {
  try {
    const validateAddressResponse: HttpResponse<ValidateAddressResponse> =
      yield axios.post("/user/validateAddress", {
        query: action.payload,
      });
    yield put(validateAddressSuccess(validateAddressResponse.data.locations));
  } catch (error: any) {
    yield put(validateAddressFailed());
    toastr.error("Error Validating Address", error.message);
  }
}

function* getAuthenticatedUserSaga() {
  try {
    const getAuthenticatedUserResponse: HttpResponse<AuthenticatedUser> =
      yield axios.get("/user");
    yield put(getAuthenticatedUserSuccess(getAuthenticatedUserResponse.data));
  } catch (error: any) {
    yield put(getAuthenticatedUserFailed());
  }
}

function* signInSaga(action: PayloadAction<SignIn>) {
  try {
    const signInResponse: HttpResponse<string> = yield axios.post(
      "/user/authenticate",
      {
        email: action.payload.email,
        password: action.payload.password,
      }
    );
    yield put(signInSuccess(signInResponse.data));
    yield put(getAuthenticatedUser());
  } catch (error: any) {
    yield put(signInFailed(error.message));
  }
}

function* requestPasswordResetSaga(
  action: PayloadAction<RequestPasswordReset>
) {
  try {
    yield axios.post("/user/requestPasswordReset", {
      email: action.payload.email,
    });
    yield put(requestPasswordResetSuccess());
  } catch (error: any) {
    yield put(requestPasswordResetFailed(error.message));
  }
}

function* changePasswordSaga(action: PayloadAction<ChangePassword>) {
  try {
    yield axios.post("/user/changePassword", {
      code: action.payload.code,
      password: action.payload.password,
    });
    yield put(changePasswordSuccess());
  } catch (error: any) {
    yield put(changePasswordFailed(error.message));
  }
}

function* resendMobileVerificationSaga(action: PayloadAction<boolean>) {
  try {
    yield axios.post("/user/resendMobileVerification", {
      isInitialSend: action.payload,
    });
    yield put(resendMobileVerificationSuccess());
  } catch (error: any) {
    yield put(resendMobileVerificationFailed(error.message));
  }
}

function* verifyMobileSaga(action: PayloadAction<VerifyMobile>) {
  try {
    yield axios.post("/user/verifyMobile", {
      code: action.payload.code,
    });
    yield put(verifyMobileSuccess());
  } catch (error: any) {
    yield put(verifyMobileFailed(error.message));
  }
}

function* saveIdentityDataSaga(action: PayloadAction<SaveIdentityData>) {
  try {
    const getPlaceDetailsResponse: HttpResponse<GetPlaceDetailsResponse> =
      yield axios.post("/user/getPlaceDetails", {
        placeId: action.payload.placeId,
      });
    const formData = new FormData();
    formData.append("identityType", action.payload.identityType);
    formData.append("identityNumber", action.payload.identityNumber);
    formData.append("middleName", action.payload.middleName);
    formData.append("licenseExpiry", action.payload.licenseExpiry);
    formData.append("dateOfBirth", action.payload.dateOfBirth);
    formData.append("idDocument", action.payload.idDocument);
    formData.append("licenseState", action.payload.licenseState);
    formData.append("rtaNumber", action.payload.rtaNumber);
    formData.append("address", getPlaceDetailsResponse.data.address);
    formData.append("suburb", getPlaceDetailsResponse.data.suburb);
    formData.append("postcode", getPlaceDetailsResponse.data.postcode);
    formData.append("state", getPlaceDetailsResponse.data.state);
    formData.append("placeId", action.payload.placeId);
    yield axios.post("/user/identityData", formData);
    yield put(saveIdentityDataSuccess());
  } catch (error: any) {
    
    yield put(saveIdentityDataFailed(error.message));
    yield error.response.data.errors && put(saveIdentityErrors(error.response.data.errors));
  }
}

function* saveBankAccountInformationSaga(
  action: PayloadAction<SaveBankAccountInformation>
) {
  try {
    yield axios.post("/user/bankAccountInformation", {
      financialInstituteName: action.payload.financialInstituteName,
      accountName: action.payload.accountName,
      accountNumber: action.payload.accountNumber,
      bsb: action.payload.bsb,
    });
    yield put(saveBankAccountInformationSuccess());
  } catch (error: any) {
    yield put(saveBankAccountInformationFailed(error.message));
  }
}

function* loadSophisticatedApplicationLinkSaga() {
  try {
    const getSophisticatedApplicationLinkResponse: HttpResponse<GetSophisticatedApplicationLinkResponse> =
      yield axios.get("/user/sophisticatedApplicationLink");
    yield put(
      loadSophisticatedApplicationLinkSuccess(
        getSophisticatedApplicationLinkResponse.data
      )
    );
  } catch (error: any) {
    yield put(loadSophisticatedApplicationLinkFailed());
    toastr.error("Error Loading Sophisticated Application Link", error.message);
  }
}

function* loadOnlineAccountRegistrationStatusSaga() {
  try
  {
      const getOnlineAccountRegistrationStatusResponse: HttpResponse<GetOnlineAccountRegistrationStatusResponse> =
        yield axios.get("/user/onlineAccountRegistrationStatus");
      yield put (
        loadOnlineAccountRegistrationStatusSuccess(
          getOnlineAccountRegistrationStatusResponse.data
        )        
      );      
  } catch (error: any) {
    yield put(loadOnlineAccountRegistrationStatusFailed());
    toastr.error("Error Loading Online Account Registration Status", error.message);
  }
}

function* saveSophisticatedApplicationSaga() {
  try {
    yield axios.post("/user/sophisticatedApplication");
    yield put(saveSophisticatedApplicationSuccess());
  } catch (error: any) {
    yield put(saveSophisticatedApplicationFailed());
    toastr.error("Error Saving Sophisticated Application", error.message);
  }
}

function* userSaga() {
  yield takeLatest(
    userSlice.actions.loadSophisticatedApplicationLink,
    loadSophisticatedApplicationLinkSaga
  );

  yield takeLatest(
    userSlice.actions.loadOnlineAccountRegistrationStatus,
    loadOnlineAccountRegistrationStatusSaga
  );
  yield takeLatest(userSlice.actions.registerUser, registerUserSaga);
  yield takeLatest(userSlice.actions.validateAddress, validateAddressSaga);
  yield takeLatest(userSlice.actions.signIn, signInSaga);
  yield takeLatest(userSlice.actions.saveIdentityData, saveIdentityDataSaga);
  yield takeLatest(
    userSlice.actions.saveBankAccountInformation,
    saveBankAccountInformationSaga
  );
  yield takeLatest(
    userSlice.actions.getAuthenticatedUser,
    getAuthenticatedUserSaga
  );
  yield takeLatest(
    userSlice.actions.requestPasswordReset,
    requestPasswordResetSaga
  );
  yield takeLatest(userSlice.actions.changePassword, changePasswordSaga);
  yield takeLatest(
    userSlice.actions.resendMobileVerification,
    resendMobileVerificationSaga
  );
  yield takeLatest(
    userSlice.actions.saveSophisticatedApplication,
    saveSophisticatedApplicationSaga
  );
  yield takeLatest(userSlice.actions.verifyMobile, verifyMobileSaga);
}

export default userSaga;
