import { memo, useEffect } from "react";
import Modal from "../modal/Modal";
import { useAppSelector } from "hooks";
import { RootState } from "store";
import { bidSubmittedModalVisibleSelector } from "selectors/uiSelectors";
import { uiSlice } from "reducers/uiSlice";
import { useAppDispatch } from "hooks";
import { SaveBankAccountInformation } from "types/saveBankAccountInformation";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { userSlice } from "reducers/userSlice";
import { clientSelector } from "selectors/clientSelectors";
import ModalNotice from "components/modalNotice/ModalNotice";
import {
  saveBankAccountInformationErrorSelector,
  saveBankAccountInformationSuccessSelector,
  savingBankAccountInformationSelector,
  authenticatedUserSelector,
} from "selectors/userSelectors";
import BankAccountForm from "components/bankAccountForm/BankAccountForm";

const BidSubmittedConfirmationModal = () => {
  const dispatch = useAppDispatch();

  const savingBankAccountInformation = useAppSelector((state: RootState) =>
    savingBankAccountInformationSelector(state)
  );

  const saveBankAccountInformationSuccess = useAppSelector((state: RootState) =>
    saveBankAccountInformationSuccessSelector(state)
  );

  const saveBankAccountInformationError = useAppSelector((state: RootState) =>
    saveBankAccountInformationErrorSelector(state)
  );

  const bidSubmittedModalVisible = useAppSelector((state: RootState) =>
    bidSubmittedModalVisibleSelector(state)
  );

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );

  const client = useAppSelector((state: RootState) => clientSelector(state));

  useEffect(() => {
    if (saveBankAccountInformationSuccess && bidSubmittedModalVisible) {
      dispatch(uiSlice.actions.showBidSubmittedModal(false));
    }
  });

  const handleCancel = () => {
    dispatch(uiSlice.actions.showBidSubmittedModal(false));
  };

  const handleSubmit = (values: any) => {
    const saveBankAccountInformation: SaveBankAccountInformation = {
      ...values,
      bsb: values.bsb.replace(/-|_/gi, ""),
      accountNumber: values.accountNumber.replace(/_/gi, ""),
    };
    dispatch(
      userSlice.actions.saveBankAccountInformation(saveBankAccountInformation)
    );
  };

  return (
    <Modal
      bodyPadding="2em 1.5em 1.5em"
      title="Bid Registered"
      onCancel={handleCancel}
      visible={bidSubmittedModalVisible}
    >
      <ModalNotice className="mb-2">
        <b style={{ fontSize: "1rem", fontWeight: 500 }}>
          We have registered your bid request for an allocation.
        </b>
      </ModalNotice>

      <ModalNotice>
        Further information in relation to your potential allocation, offer and
        acceptance will be emailed upon confirmation from the Lead Manager of
        the deal.
      </ModalNotice>
      {client?.settings?.workflow?.enableBankAccountConfirmation && authenticatedUser && authenticatedUser.noBids > 1 && (
        <>
          <ModalNotice className="mb-2 mt-4" align="start">
            <b style={{ fontSize: "1rem", fontWeight: 500 }}>
              Please confirm your bank account details:
            </b>
          </ModalNotice>
          <ModalNotice className="mb-4" align="start">
            In order to refund the scale back/decline/reject deals to your
            nominated bank account.
          </ModalNotice>
          <BankAccountForm
            handleSubmit={handleSubmit}
            submitButtonText="Update"
            error={saveBankAccountInformationError}
            busy={savingBankAccountInformation}
          />
        </>
      )}
      { !authenticatedUser ||
        ((authenticatedUser.noBids <= 1  || !client?.settings?.workflow?.enableBankAccountConfirmation) && (
          <PrimaryButton
            color="white"
            className="mt-4"
            background={client?.settings?.theme?.primaryColorHex}
            onClick={handleCancel}
          >
            Ok
          </PrimaryButton>
        ))}
    </Modal>
  );
};

export default memo(BidSubmittedConfirmationModal);
