import { put, takeLatest } from "redux-saga/effects";
import { HttpResponse } from "types/httpResponse";
import { Client } from "types/client";
import {
  loadClientFailed,
  loadClientSuccess,
  clientSlice,
} from "../reducers/clientSlice";

import axios from "axios";

function* loadClientSaga() {
  try {
    const clientResponse: HttpResponse<Client> = yield axios.get("/client");
    yield put(loadClientSuccess(clientResponse.data));
  } catch (error: any) {
    yield put(loadClientFailed(`Error Loading Client: ${error.message}`));
  }
}

function* clientSaga() {
  yield takeLatest(clientSlice.actions.loadClient, loadClientSaga);
}

export default clientSaga;
