import { ChangeEventHandler, memo } from "react";
import styled from "styled-components";
import InputError from "components/inputError/InputError";
import { Form } from "react-bootstrap";

interface SelectProps {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  isTouched?: boolean;
  errorMessage?: string | undefined;
  id?: string | undefined;
  children: any;
}

const StyledSelect = styled(Form.Select)<{ $isInvalid: boolean | undefined }>`
  border-color: ${(props) => (props.$isInvalid ? "red" : "#979797")} !important;
  line-height: 40px;
  font-size: 0.9rem;
  padding: 0 0 0 15px;
  border-radius: 10px;
  box-shadow: none !important;

  &:has(option:disabled:checked) {
    color: gray;
  }

  option {
    color: black;    
  }
`;

const Select = (props: SelectProps) => {
  const { children, isTouched, errorMessage } = props;

  const showError = isTouched && errorMessage !== undefined;

  return (
    <>
      <StyledSelect
        spellCheck={false}
        value={props.value}
        onChange={props.onChange}
        $isInvalid={showError}
        children={children}
      />
      <InputError visible={showError}>{errorMessage}</InputError>
    </>
  );
};

Select.defaultProps = {
  errorMessage: undefined,
  isTouched: false,
};

export default memo(Select);
