import { memo, useEffect, useState } from "react";
import Modal from "../modal/Modal";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
//import { userSlice } from "reducers/userSlice";
import { RootState } from "store";
import { authenticatedUserSelector, onlineAccountRegistrationLoadingSelector, onlineAccountRegistrationStatusSelector, onlineAccountRegistrationUrlSelector } from "selectors/userSelectors";
import { Container } from "react-bootstrap";
import ModalNotice from "components/modalNotice/ModalNotice";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import LinkButton from "components/linkButton/LinkButton";
import { clientSelector } from "selectors/clientSelectors";
import {
  identityDocumentModalVisibleSelector,
  identityDocumentSubmittedModalVisibleSelector,
  verifyMobileSuccessModalVisibleSelector,
} from "selectors/uiSelectors";

const WelcomeNoticeModal = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );

  const verifyMobileSuccessModalVisible = useAppSelector((state: RootState) =>
    verifyMobileSuccessModalVisibleSelector(state)
  );

  const identityDocumentModalVisible = useAppSelector((state: RootState) =>
    identityDocumentModalVisibleSelector(state)
  );

  const identityDocumentSubmittedModalVisible = useAppSelector(
    (state: RootState) => identityDocumentSubmittedModalVisibleSelector(state)
  );

  const onlineAccountRegistrationUrl = useAppSelector((state: RootState) =>
    onlineAccountRegistrationUrlSelector(state)
  );
    
  const onlineAccountRegistrationStatus = useAppSelector((state: RootState) =>
    onlineAccountRegistrationStatusSelector(state)
  );
    
  const onlineAccountRegistrationLoading = useAppSelector((state: RootState) =>
    onlineAccountRegistrationLoadingSelector(state)
  );

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    if (
      authenticatedUser &&
      !verifyMobileSuccessModalVisible &&
      !identityDocumentModalVisible &&
      !identityDocumentSubmittedModalVisible &&
      authenticatedUser.hasVerifiedMobile &&
      authenticatedUser.verificationStatus !== "Verified" &&
      !authenticatedUser.hasExceededMaxVerificationAttempts &&
      !onlineAccountRegistrationLoading &&
      !client?.settings?.workflow?.enableOnlineAccountRegistrationForm &&
      !localStorage.getItem(`welcome_dismissed_${authenticatedUser.id}`)
    ) {
      setShowWelcomeModal(true);
    }
    else
    {
      setShowWelcomeModal(false);  
    }
  }, [
    authenticatedUser,
    identityDocumentModalVisible,
    identityDocumentSubmittedModalVisible,
    verifyMobileSuccessModalVisible,
    onlineAccountRegistrationLoading,
    onlineAccountRegistrationStatus,
    client?.settings?.workflow?.enableOnlineAccountRegistrationForm
  ]);

  const handleCancel = () => {
    setShowWelcomeModal(false);
    localStorage.setItem(`welcome_dismissed_${authenticatedUser?.id}`, "true");
  };

  const handleVerifyNow = () => {
    setShowWelcomeModal(false);
    if (client?.settings?.workflow?.enableOnlineAccountRegistrationForm) {
      window.open(onlineAccountRegistrationUrl, "_blank");
    }
    else {
      dispatch(uiSlice.actions.showIdentityDocumentModal(true));
    }
  };

  return (
    <Modal
      title={`Welcome, ${authenticatedUser?.firstName ?? "Unknown User"}`}
      onCancel={handleCancel}
      visible={showWelcomeModal}
      bodyPadding="1.5em 2em"
    >
      <Container className="px-0">
        <ModalNotice className="mb-4 text-center" fontSize="1rem">
          {client?.settings.workflow?.enableOnlineAccountRegistrationForm && 
            <span>
              There are many deals you will find here.
              To be able to bid you need to complete the Online Account Registration.
            </span>
          }
          {!client?.settings.workflow?.enableOnlineAccountRegistrationForm && client?.settings.workflow?.enableGreenIDVerification && 
            <span>
              There are many deals you will find here.
              To be able to bid we need to your identity.
            </span>
          }
          <br />
          <b className="mt-3" style={{ fontSize: "1.3rem", fontWeight: 500 }}>
          {client?.settings.workflow?.enableOnlineAccountRegistrationForm && 
            <span>
              Complete now, don't let any deals slip away!
            </span>
          }
          {!client?.settings.workflow?.enableOnlineAccountRegistrationForm && client?.settings.workflow?.enableGreenIDVerification && 
            <span>
              Verify now, don't let any deals slip away!
            </span>
          }            
          </b>
        </ModalNotice>
        <PrimaryButton
          background={client?.settings?.theme?.primaryColorHex}
          type="button"
          color="white"
          onClick={handleVerifyNow}
          disabled={
            onlineAccountRegistrationLoading ||
            (client?.settings?.workflow?.enableOnlineAccountRegistrationForm && 
            (onlineAccountRegistrationStatus === -1 || onlineAccountRegistrationStatus === 1 ||  onlineAccountRegistrationStatus === 3))
          }
        >
          {client?.settings?.theme?.welcomeDialogVerifyButtonText || 'Verify Your ID Now!'}
        </PrimaryButton>
        <LinkButton className="mt-2" onClick={handleCancel}>
          Verify Later
        </LinkButton>
      </Container>
    </Modal>
  );
};

export default memo(WelcomeNoticeModal);
