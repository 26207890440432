import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { GetClosureInformation } from "utilities/time";
import DateTile from "../dateTile/DateTile";
import { FONT_SIZES } from "../../constants";

const DateWrapper = styled.div<{ textAlign: string }>`
  padding-top: 2px;
  text-align: ${(props) => props.textAlign};
  padding-bottom: 2px;
  display: flex;
  flex: 1;
  @media (max-width: 1240px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Expired = styled.div<{ $fontSize: string }>`
  flex-direction: column;
  display: flex;
  color: #8e8e8e;
  font-weight: 700;
  flex: 1;
  font-size: ${(props) => props.$fontSize};

  > i {
    font-weight: 400;
    font-size: ${FONT_SIZES.Medium};
  }

  > Button {
    font-size: ${FONT_SIZES.Medium};
    min-height: auto;
    margin: 0;
    padding: 0;
    text-align: left;
    width: auto;
  }
`;

interface ClosesOnCellProps {
  closesOn: string;
  fontSize?: string;
  expiredFontSize?: string;
  primaryColorHex: string;
  textAlign?: string;
}

const ClosesOnCell = (props: ClosesOnCellProps) => {
  const {
    closesOn,
    fontSize,
    expiredFontSize = FONT_SIZES.Large,
    textAlign = "left",
  } = props;

  const [, setCurrentDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentDate(new Date().toLocaleString());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const closureInformation = GetClosureInformation(closesOn);

  return (
    <DateWrapper textAlign={textAlign}>
      {!closureInformation.expired && (
        <DateTile
          fontSize={fontSize}
          date={closureInformation.closesOnSydneyConverted}
        />
      )}
      {closureInformation.expired && (
        <Expired $fontSize={expiredFontSize}>Deal Closed</Expired>
      )}
    </DateWrapper>
  );
};

export default memo(ClosesOnCell);
