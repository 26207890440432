import { memo } from "react";
import Modal from "components/modal/Modal";
import { Container } from "react-bootstrap";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import ModalNotice from "components/modalNotice/ModalNotice";
import { RootState } from "store";
import { clientSelector } from "selectors/clientSelectors";
import { useAppSelector } from "hooks";
interface ConfirmationModalProps {
  onClose: () => void;
  title: string;
  children: any;
  visible: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { onClose, title, children, visible } = props;

  const client = useAppSelector((state: RootState) => clientSelector(state));

  return (
    <Modal title={title} onCancel={onClose} visible={visible}>
      <Container>
        <ModalNotice
          weight="500"
          align="start"
          className="mb-4"
          fontSize="1rem"
        >
          {children}
        </ModalNotice>
        <PrimaryButton
          background={client?.settings?.theme?.primaryColorHex}
          onClick={onClose}
          color="white"
        >
          Ok
        </PrimaryButton>
      </Container>
    </Modal>
  );
};

export default memo(ConfirmationModal);
