import { RootState } from "store";

export const signUpModalVisibleSelector = (state: RootState) =>
  state.ui.signUpModalVisible;

export const signInModalVisibleSelector = (state: RootState) =>
  state.ui.signInModalVisible;

export const passwordResetModalVisibleSelector = (state: RootState) =>
  state.ui.passwordResetModalVisible;

export const identityDocumentModalVisibleSelector = (state: RootState) =>
  state.ui.identityDocumentModalVisible;

export const identityDocumentSubmittedModalVisibleSelector = (
  state: RootState
) => state.ui.identityDocumentSubmittedModalVisible;

export const bidSubmittedModalVisibleSelector = (state: RootState) =>
  state.ui.bidSubmittedModalVisible;

export const sophisticatedApplicationSubmittedModalVisibleSelector = (
  state: RootState
) => state.ui.sophisticatedApplicationSubmittedModalVisible;

export const verifyMobileSuccessModalVisibleSelector = (state: RootState) =>
  state.ui.verifyMobileSuccessModalVisible;

export const bankAccountModalVisibleSelector = (state: RootState) =>
  state.ui.bankAccountModalVisible;

export const contactUsModalVisibleSelector = (state: RootState) =>
  state.ui.contactUsModalVisible;
