import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from "styled-components/cssprop";
import TopBar from "components/topbar/TopBar";
import SignUpModal from "components/signUpModal/SignUpModal";
import SignInModal from "components/signInModal/SignInModal";
import PasswordResetModal from "components/passwordResetModal/PasswordResetModal";
import MobileVerificationModal from "components/mobileVerificationModal/MobileVerificationModal";
import WelcomeNoticeModal from "components/welcomeNoticeModal/WelcomeNoticeModal";
import BankAccountModal from "components/bankAccountModal/BankAccountModal";
import OffersPage from "pages/offers/OffersPage";
import { useEffect } from "react";
import { clientSlice } from "reducers/clientSlice";
import { userSlice } from "reducers/userSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import {
  clientSelector,
  loadClientErrorSelector,
} from "selectors/clientSelectors";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { authenticatedUserSelector, tokenSelector } from "selectors/userSelectors";
import { uiSlice } from "reducers/uiSlice";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import { verifyMobileSuccessModalVisibleSelector } from "selectors/uiSelectors";
import ContactUsModal from "components/contactUsModal/contactUsModal";

axios.defaults.baseURL = process.env["REACT_APP_API_BASE_URL"];

const SetUpAxiosInterceptors = (dispatch: any) => {
  axios.interceptors.request.use(function (request: any) {
    request.headers = { "client-key": process.env["REACT_APP_CLIENT_KEY"] };
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      request.headers = {
        ...request.headers,
        ...{ authorization: `Bearer ${jwt}` },
      };
    }
    return request;
  });

  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch(userSlice.actions.signOut());
      }
      if (error.response && error.response.status === 400) {
        let errorMessages = [];
        for (const property in error.response.data.errors) {
          errorMessages.push(error.response.data.errors[property][0]);
        }
        if (errorMessages.length > 0)
          error.message = errorMessages.join("\r\n");
        else if (error.response.data.detail)
          error.message = error.response.data.detail;
      }
      return Promise.reject(error);
    }
  );
};

const SkeletonWrapper = styled.div`
  padding: 5px;
`;

function App() {
  const dispatch = useAppDispatch();

  SetUpAxiosInterceptors(dispatch);

  const client = useAppSelector((state: RootState) => clientSelector(state));
  const user = useAppSelector((state: RootState) => authenticatedUserSelector(state));

  const jwt = useAppSelector((state: RootState) => tokenSelector(state));

  const loadClientError = useAppSelector((state: RootState) =>
    loadClientErrorSelector(state)
  );

  const verifyMobileSuccessModalVisible = useAppSelector((state: RootState) =>
    verifyMobileSuccessModalVisibleSelector(state)
  );

  // const onlineAccountRegistrationStatus = useAppSelector((state: RootState) =>
  //   onlineAccountRegistrationStatusSelector(state)
  // );

  const enableOnlineAccountRegistrationForm = client?.settings?.workflow?.enableOnlineAccountRegistrationForm;

  useEffect(() => {
    if (!client) {
      dispatch(clientSlice.actions.loadClient());
      return;
    }
    if (client && jwt) {
      dispatch(userSlice.actions.getAuthenticatedUser());

      if (
        window.location.hash === "#sign-up" ||
        window.location.hash === "#sign-up"
      ) {
        window.location.hash = "";
      }
      return;
    } else if (client && !jwt) {
      if (window.location.hash === "#sign-up") {
        dispatch(uiSlice.actions.showSignUpModal(true));
      } else if (window.location.hash === "#sign-in") {
        dispatch(uiSlice.actions.showSignInModal(true));
      }
    }
  }, [client, jwt, dispatch]);

  useEffect(() => {
      // Fetch the status of the Account Fast if not Verified
      if (jwt && enableOnlineAccountRegistrationForm && user && user?.verificationStatus !== 'Verified') {
        dispatch(userSlice.actions.loadOnlineAccountRegistrationStatus());

      }      
  }, [enableOnlineAccountRegistrationForm, user, jwt, dispatch]);

  
  if (!client && !loadClientError)
    return (
      <SkeletonWrapper>
        <Skeleton
          style={{ marginBottom: 5, lineHeight: "70px", borderRadius: 0 }}
        />
        <Skeleton
          style={{ marginBottom: 5, lineHeight: "35px", borderRadius: 0 }}
          count={5}
        />
      </SkeletonWrapper>
    );

  if (loadClientError)
    return (
      <Alert variant={"danger"} style={{ textAlign: "center", margin: 5 }}>
        {loadClientError}
      </Alert>
    );

  return (
    <div className="App">
      <TopBar />
      <OffersPage />
      <SignUpModal />
      <SignInModal />
      <PasswordResetModal />
      <ConfirmationModal
        visible={!client?.settings?.workflow?.enableOnlineAccountRegistrationForm && verifyMobileSuccessModalVisible}
        onClose={() => {
          dispatch(uiSlice.actions.showVerifyMobileSuccessModal(false));
        }}
        title={client?.settings?.workflow?.enableEmail2FA ? "Email Verification" : "Mobile Verification"}
      >{ client?.settings?.workflow?.enableEmail2FA
        ? "Your email has been verified"
        : "Your mobile number has been verified"
        }
      </ConfirmationModal>
      <MobileVerificationModal />
      <BankAccountModal />
      <WelcomeNoticeModal />
      <ContactUsModal />
    </div>
  );
}

export default App;
