import * as Yup from "yup";
import { memo } from "react";
import { Container, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import { RequestPasswordReset } from "../../types/requestPasswordReset";
import { RootState } from "store";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { clientSelector } from "selectors/clientSelectors";
import Input from "components/input/Input";
import {
  requestingPasswordResetSelector,
  requestPasswordResetErrorSelector,
} from "selectors/userSelectors";
import ModalNotice from "components/modalNotice/ModalNotice";
import { userSlice } from "reducers/userSlice";
import FormError from "components/formError/FormError";

const PasswordResetStep1Form = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const requestingPasswordReset = useAppSelector((state: RootState) =>
    requestingPasswordResetSelector(state)
  );

  const requestPasswordResetError = useAppSelector((state: RootState) =>
    requestPasswordResetErrorSelector(state)
  );

  const schema = Yup.object({
    email: Yup.string().required("Email is a required field."),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
    },
    onSubmit: (values: any) => {
      const requestPasswordReset: RequestPasswordReset = {
        ...values,
      };
      dispatch(userSlice.actions.requestPasswordReset(requestPasswordReset));
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} autoComplete="off">
      <Container>
        <ModalNotice className="mb-4">
          An 8 character code will be sent to your email for verification.
        </ModalNotice>
        <Form.Group className="mb-4" controlId="email">
          <Input
            type="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            isTouched={formik.touched.email}
            errorMessage={formik.errors.email}
          />
        </Form.Group>
        <FormError message={requestPasswordResetError} />
        <PrimaryButton
          color="white"
          background={client?.settings?.theme?.primaryColorHex}
          type="submit"
          disabled={requestingPasswordReset}
          loading={requestingPasswordReset}
        >
          Send
        </PrimaryButton>
      </Container>
    </Form>
  );
};

export default memo(PasswordResetStep1Form);
