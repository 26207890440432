import { memo } from "react";
import styled from "styled-components";
import { Offer } from "../../types/offer";
import { Button } from "react-bootstrap";
import Currency from "components/currency/Currency";
import { GetClosureInformation } from "utilities/time";
import { AuthenticatedUser } from "types/authenticatedUser";
import { FONT_SIZES } from "../../constants";
import { LightenDarkenColor } from "lighten-darken-color";
import { useAppSelector } from "hooks";
import { RootState } from "store";
import { clientSelector } from "selectors/clientSelectors";

const GridButton = styled(Button)<{
  $primaryColorHex: string;
  $showCollapsedView: boolean;
  $fontSize: string;
}>`
  box-shadow: none !important;
  padding-top: ${(props) => (props.$showCollapsedView ? "2px" : "9px")};
  padding-bottom: ${(props) => (props.$showCollapsedView ? "5px" : "12px")};
  padding-left: ${(props) => (props.$showCollapsedView ? "12px" : "22px")};
  padding-right: ${(props) => (props.$showCollapsedView ? "13px" : "22px")};
  margin-top: ${(props) => (props.$showCollapsedView ? "2px" : "0px")};
  color: white !important;
  border: none;
  background: ${(props) => props.$primaryColorHex} !important;
  font-weight: 600;
  font-size: ${(props) => props.$fontSize};
  :hover {
    background: ${(props) =>
      LightenDarkenColor(props.$primaryColorHex, -50)} !important;
  }
`;

const Wrapper = styled.div<{ $showCollapsedView: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-top: ${(props) => (props.$showCollapsedView ? "-1px" : "4px")};
`;

const BidHistory = styled.div`
  color: #0c6800;
  font-size: ${FONT_SIZES.Tiny};
  white-space: break-spaces;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

interface DetailsCellProps {
  data: Offer;
  showCollapsedView: boolean;
  authenticatedUser?: AuthenticatedUser;
  setSelectedOffer: (data: Offer) => void;
  primaryColorHex: string;
  buttonFontSize: string;
}

const DetailsCell = (props: DetailsCellProps) => {
  const {
    data,
    showCollapsedView,
    setSelectedOffer,
    authenticatedUser,
    primaryColorHex,
    buttonFontSize    
  } = props;

  const client = useAppSelector((state: RootState) => clientSelector(state));
  const closureInformation = GetClosureInformation(data.closesOn);
  
  const dealIsWholesaleAndUserIsSophisticated = (): boolean => {
    return (
      data.isWholesale &&
      authenticatedUser?.sophisticatedApplicationStatus === "Approved"
    );
  };

  const redirectToUrl = () => {
    window.open(client?.settings?.theme?.redirectUrl, "_blank");  
  }

  return (
    <Wrapper $showCollapsedView={showCollapsedView}>
      {showCollapsedView && (
        <Currency color="#5BC64E" fontWeight="700" amount={data.sharePrice} />
      )}
      {!closureInformation.expired &&
        (!data.isWholesale || dealIsWholesaleAndUserIsSophisticated() || 
         client?.settings?.offers?.alwaysShowWholesaleOffers) && (
          <ButtonWrapper>
            <GridButton
              $showCollapsedView={showCollapsedView}
              $primaryColorHex={primaryColorHex}
              onClick={() => client?.settings?.theme?.redirectMode ? redirectToUrl() : setSelectedOffer(data)}
              $fontSize={buttonFontSize}
              disabled={!(client?.settings?.workflow?.showLiveDealInfoWhenBidRegistered ?? true) && data.lastBidOnUtc !== null}
            >
              Invest
            </GridButton>
            {data.lastBidOnUtc !== null && (
              <BidHistory>You bid on this deal!</BidHistory>
            )}
          </ButtonWrapper>
        )}
    </Wrapper>
  );
};

export default memo(DetailsCell);
