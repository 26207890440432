import { toastr } from "react-redux-toastr";

import { put, takeLatest } from "redux-saga/effects";
import { HttpResponse } from "types/httpResponse";
import { GetOffersResponse } from "types/getOffersResponse";
import { GetOfferDocument } from "types/getOfferDocument";
import { SaveBidActivity } from "types/saveBidActivity";
import {
  loadOfferDocumentFailed,
  loadOfferDocumentSuccess,
  loadOffersFailed,
  loadOffersSuccess,
  offerSlice,
  loadBidLinkSuccess,
  saveBidActivitySuccess,
  saveBidActivityFailed,
  loadBidLinkFailed,
} from "../reducers/offerSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { GetBidLinkResponse } from "types/getBidLinkResponse";
import { GetBidLink } from "types/getBidLink";

import axios from "axios";

function* loadOffersSaga() {
  try {
    const getOffersResponse: HttpResponse<GetOffersResponse> = yield axios.get(
      "/offer"
    );
    yield put(loadOffersSuccess(getOffersResponse.data.offers));
  } catch (error: any) {
    yield put(loadOffersFailed());
    toastr.error("Error Loading Offers", error.message);
  }
}

function* loadBidLinkSaga(action: PayloadAction<GetBidLink>) {
  try {
    const getBidLinkResponse: HttpResponse<GetBidLinkResponse> =
      yield axios.post("/offer/bidlink", {
        offerId: action.payload.offerId,
      });
    yield put(loadBidLinkSuccess(getBidLinkResponse.data));
  } catch (error: any) {
    yield put(loadBidLinkFailed());
    toastr.error("Error Loading Bid Link", error.message);
  }
}

function* loadOfferDocumentSaga(action: PayloadAction<GetOfferDocument>) {
  try {
    const loadOfferDocumentResponse: HttpResponse<any> = yield axios.post(
      "/offer/document",
      {
        offerId: action.payload.offerId,
        offerDocumentId: action.payload.offerDocumentId,
      },
      {
        responseType: "arraybuffer",
      }
    );
    var a = document.createElement("a");
    document.body.appendChild(a);
    var file = new Blob([loadOfferDocumentResponse.data]);
    var fileURL = window.URL.createObjectURL(file);
    a.href = fileURL;
    a.download = action.payload.fileName;
    a.click();
    yield put(loadOfferDocumentSuccess());
  } catch (error: any) {
    yield put(loadOfferDocumentFailed());
    toastr.error("Error Loading Offer Document", error.message);
  }
}

function* saveBidActivitySaga(action: PayloadAction<SaveBidActivity>) {
  try {
    yield axios.post("/offer/bid", {
      offerId: action.payload.offerId,
    });
    yield put(saveBidActivitySuccess());
  } catch (error: any) {
    yield put(saveBidActivityFailed(error.message));
    toastr.error("Error Saving Bid Activity", error.message);
  }
}

function* offerSaga() {
  yield takeLatest(offerSlice.actions.loadOffers, loadOffersSaga);
  yield takeLatest(offerSlice.actions.loadOfferDocument, loadOfferDocumentSaga);
  yield takeLatest(offerSlice.actions.loadBidLink, loadBidLinkSaga);
  yield takeLatest(offerSlice.actions.saveBidActivity, saveBidActivitySaga);
}

export default offerSaga;
