import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { GetClosureInformation } from "utilities/time";
import CountDown from "../countDown/CountDown";

const ColumnWrapper = styled.div`
  display: flex;
`;

interface CountDownCellProps {
  closesOn: string;
  primaryColorHex: string;
}

const closesSoonDays = 7;

const CountDownCell = (props: CountDownCellProps) => {
  const { closesOn, primaryColorHex } = props;

  const [, setCurrentDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentDate(new Date().toLocaleString());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const closureInformation = GetClosureInformation(closesOn);
  const closesSoon =
    closureInformation.daysRemaining < closesSoonDays &&
    closureInformation.daysRemaining > 0;

  return (
    <ColumnWrapper>
      {closesSoon && (
        <CountDown
          remaining={closureInformation.remaining}
          color={primaryColorHex}
        />
      )}
    </ColumnWrapper>
  );
};

export default memo(CountDownCell);
