import { RootState } from "store";

export const tokenSelector = (state: RootState) => state.user.token;

export const displayNameSelector = (state: RootState) => {
  if (!state.user.authenticatedUser) return null;
  return `${state.user.authenticatedUser.firstName} ${state.user.authenticatedUser.lastName}`;
};

export const authenticatedUserSelector = (state: RootState) =>
  state.user.authenticatedUser;

export const registeringUserSelector = (state: RootState) =>
  state.user.registeringUser;

export const registerUserSuccessSelector = (state: RootState) =>
  state.user.registerUserSuccess;

export const registerUserErrorMessageSelector = (state: RootState) =>
  state.user.registerUserErrorMessage;

export const registerUserErrorsSelector = (state: RootState) =>
  state.user.registerUserErrors;

export const validatingAddressSelector = (state: RootState) =>
  state.user.validatingAddress;

export const validatedAddressesSelector = (state: RootState) =>
  state.user.validatedAddresses;

export const signingInSelector = (state: RootState) => state.user.signingIn;

export const signInSuccessSelector = (state: RootState) =>
  state.user.signInSuccess;

export const signInErrorSelector = (state: RootState) => state.user.signInError;

export const requestingPasswordResetSelector = (state: RootState) =>
  state.user.requestingPasswordReset;

export const requestPasswordResetSuccessSelector = (state: RootState) =>
  state.user.requestPasswordResetSuccess;

export const requestPasswordResetErrorSelector = (state: RootState) =>
  state.user.requestPasswordResetError;

export const changingPasswordSelector = (state: RootState) =>
  state.user.changingPassword;

export const changePasswordSuccessSelector = (state: RootState) =>
  state.user.changePasswordSuccess;

export const changePasswordErrorSelector = (state: RootState) =>
  state.user.changePasswordError;

export const savingIdentityDataSelector = (state: RootState) =>
  state.user.savingIdentityData;

export const saveIdentityDataSuccessSelector = (state: RootState) =>
  state.user.saveIdentityDataSuccess;

export const saveIdentityDataErrorSelector = (state: RootState) =>
  state.user.saveIdentityDataError;

export const saveIdentityErrorsSelector = (state: RootState) =>
  state.user.saveIdentityErrors;

export const verifyingMobileSelector = (state: RootState) =>
  state.user.verifyingMobile;

export const verifyMobileSuccessSelector = (state: RootState) =>
  state.user.verifyMobileSuccess;

export const verifyMobileErrorSelector = (state: RootState) =>
  state.user.verifyMobileError;

export const resendingMobileVerificationSelector = (state: RootState) =>
  state.user.resendingMobileVerification;

export const resendMobileVerificationSuccessSelector = (state: RootState) =>
  state.user.resendMobileVerificationSuccess;

export const resendMobileVerificationErrorSelector = (state: RootState) =>
  state.user.resendMobileVerificationError;

export const sophisticatedApplicationLinkLoadingSelector = (state: RootState) =>
  state.user.sophisticatedApplicationLinkLoading;

export const sophisticatedApplicationLinkSelector = (state: RootState) =>
  state.user.sophisticatedApplicationLink;

export const saveSophisticatedApplicationSuccessSelector = (state: RootState) =>
  state.user.saveSophisticatedApplicationSuccess;

export const onlineAccountRegistrationLoadingSelector = (state: RootState) =>
  state.user.onlineAccountRegistrationLoading;
  
export const onlineAccountRegistrationStatusSelector = (state: RootState) =>
  state.user.onlineAccountRegistrationStatus;

export const onlineAccountRegistrationUrlSelector = (state: RootState) =>
  state.user.onlineAccountRegistrationUrl;

export const savingBankAccountInformationSelector = (state: RootState) =>
  state.user.savingBankAccountInformation;

export const saveBankAccountInformationSuccessSelector = (state: RootState) =>
  state.user.saveBankAccountInformationSuccess;

export const saveBankAccountInformationErrorSelector = (state: RootState) =>
  state.user.saveBankAccountInformationError;
