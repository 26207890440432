import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UiState {
  signUpModalVisible: boolean;
  signInModalVisible: boolean;
  passwordResetModalVisible: boolean;
  identityDocumentModalVisible: boolean;
  identityDocumentSubmittedModalVisible: boolean;
  bidSubmittedModalVisible: boolean;
  sophisticatedApplicationSubmittedModalVisible: boolean;
  verifyMobileSuccessModalVisible: boolean;
  bankAccountModalVisible: boolean;
  contactUsModalVisible: boolean;
}

const initialState: UiState = {
  signUpModalVisible: false,
  signInModalVisible: false,
  passwordResetModalVisible: false,
  identityDocumentModalVisible: false,
  identityDocumentSubmittedModalVisible: false,
  bidSubmittedModalVisible: false,
  sophisticatedApplicationSubmittedModalVisible: false,
  verifyMobileSuccessModalVisible: false,
  bankAccountModalVisible: false,
  contactUsModalVisible: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showSignUpModal: (state, action: PayloadAction<boolean>) => {
      state.signUpModalVisible = action.payload;
      if (!action.payload && window.location.hash !== "")
        window.location.hash = "";
    },
    showSignInModal: (state, action: PayloadAction<boolean>) => {
      state.signInModalVisible = action.payload;
      if (!action.payload && window.location.hash !== "")
        window.location.hash = "";
    },
    showPasswordResetModal: (state, action: PayloadAction<boolean>) => {
      state.passwordResetModalVisible = action.payload;
    },
    showIdentityDocumentModal: (state, action: PayloadAction<boolean>) => {
      state.identityDocumentModalVisible = action.payload;
    },
    showBankAccountModal: (state, action: PayloadAction<boolean>) => {
      state.bankAccountModalVisible = action.payload;
    },
    showContactUsModal: (state, action: PayloadAction<boolean>) => {
      state.contactUsModalVisible = action.payload;
    },
    showIdentityDocumentSubmittedModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.identityDocumentSubmittedModalVisible = action.payload;
    },
    showBidSubmittedModal: (state, action: PayloadAction<boolean>) => {
      state.bidSubmittedModalVisible = action.payload;
    },
    showVerifyMobileSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.verifyMobileSuccessModalVisible = action.payload;
    },
    showSophisticatedApplicationSubmittedModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.sophisticatedApplicationSubmittedModalVisible = action.payload;
    },
  },
});

export const { showSignUpModal } = uiSlice.actions;

export const { showSignInModal } = uiSlice.actions;

export const { showBankAccountModal } = uiSlice.actions;

export const { showVerifyMobileSuccessModal } = uiSlice.actions;

export const { showPasswordResetModal } = uiSlice.actions;

export const { showIdentityDocumentModal } = uiSlice.actions;

export const { showBidSubmittedModal } = uiSlice.actions;

export default uiSlice.reducer;
