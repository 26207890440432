export const WINDOW_MESSAGE_TYPES = {
  SophisticatedApplicationSubmitted: "SOPHISTICATED_APPLICATION_SUBMITTED",
  bidSubmitted: "BID_SUBMITTED",
};

export const FONT_SIZES = {
  Tiny: "0.7rem",
  Small: "0.7rem",
  Medium: "0.8rem",
  Large: "1rem",
  ExtraLarge: "1.4rem",
};
