import { memo } from "react";
import styled from "styled-components";

type Align = "start" | "center" | "end" | "";

interface ModalNoticeProps {
  children: any;
  align?: Align;
  color?: string;
  className?: string;
  weight?: string;
  fontSize?: string;
  separatorColor?: string;
}

const Wrapper = styled.div<{
  $align: Align;
  $color: string;
  $weight: string;
  $fontSize: string;
  $separatorColor: string | undefined;
}>`
  border-top: ${(props) =>
    props.$separatorColor ? `solid 1px ${props.$separatorColor}` : "none"};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$align};
  font-size: ${(props) => props.$fontSize};
  color: ${(props) => props.$color};
  font-weight: ${(props) => props.$weight};
  align-items: ${(props) => props.$align};
  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
`;

const ModalNotice = (props: ModalNoticeProps) => {
  const {
    children,
    align,
    color,
    className,
    weight,
    fontSize,
    separatorColor,
  } = props;
  return (
    <Wrapper
      $separatorColor={separatorColor}
      className={className}
      $align={align ?? ""}
      $fontSize={fontSize ?? "0.9rem"}
      $color={color ?? "#707070"}
      $weight={weight ?? "400"}
    >
      {children}
    </Wrapper>
  );
};

export default memo(ModalNotice);
