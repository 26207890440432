import { useAppSelector } from "hooks";
import { memo } from "react";
import { authenticatedUserSelector, onlineAccountRegistrationLoadingSelector, onlineAccountRegistrationStatusSelector, onlineAccountRegistrationUrlSelector } from "selectors/userSelectors";
import { RootState } from "store";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import VerificationBadge from "components/verificationBadge/VerificationBadge";
import VerificationExceededBadge from "components/verificationExceededBadge/VerificationExceededBadge";

import {
  faExclamationTriangle,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlinePending } from "react-icons/md";

import { useAppDispatch } from "hooks";
import { uiSlice } from "reducers/uiSlice";
//import { userSlice } from "reducers/userSlice";
import { clientSelector } from "selectors/clientSelectors";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { FONT_SIZES } from "../../constants";

const CompleteNowButton = styled(PrimaryButton)`
  width: auto;
`;

const Label = styled.div`
  font-size: ${FONT_SIZES.Medium};
  line-height: $ ${FONT_SIZES.Medium};
  height: 0.8rem;
  margin-left: 0.8rem;
`;

const Wrapper = styled.div`
  display: flex;
`;

const VerificationHeader = () => {
  const dispatch = useAppDispatch();

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );
  
  const onlineAccountRegistrationLoading = useAppSelector((state: RootState) =>
    onlineAccountRegistrationLoadingSelector(state)
  );

  const onlineAccountRegistrationUrl = useAppSelector((state: RootState) =>
    onlineAccountRegistrationUrlSelector(state)
  );

  const onlineAccountRegistrationStatus = useAppSelector((state: RootState) =>
    onlineAccountRegistrationStatusSelector(state)
  );

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const handleVerifyNow = () => {
    dispatch(uiSlice.actions.showIdentityDocumentModal(true));
  };

  const handleContactUs = () => {    
    dispatch(uiSlice.actions.showContactUsModal(true));
  };

  const handleCompleteNow = () => {
    window.open(onlineAccountRegistrationUrl, "_blank");    
  };

  if (!authenticatedUser) return <></>;

  if (client?.settings?.workflow?.enableOnlineAccountRegistrationForm)
  {
    if (onlineAccountRegistrationLoading) {
      return (
        <Wrapper>
          <Spinner animation="border" size="sm"/>
          <Label>Loading Online Application Status...</Label>
        </Wrapper>
      )
    }
    else{
      if (authenticatedUser.verificationStatus === "Verified" ||
          onlineAccountRegistrationStatus === 2) {
        return (<VerificationBadge
          backgroundColor="#5BC64E"
          icon={faUserCheck}
          text="Place a bid now!"
          header="Your Info has been verified"
        ></VerificationBadge>);
      }
      if (onlineAccountRegistrationStatus === 1) {
        return (<VerificationBadge
        backgroundColor="#838383"
        reactIcon={<MdOutlinePending />}
        text="Admin is verifying your information"
        header="Pending..."
      ></VerificationBadge>);
      }
      if (onlineAccountRegistrationStatus === 3) {
        return (<VerificationBadge
          backgroundColor="#BF0303"
          reactIcon={<MdOutlinePending />}
          text="Please click contact us to resolve the issue!"
          header="Has some issues to verify your info"
        >
          <PrimaryButton
          onClick={handleContactUs}
          fontSize="0.8rem"
          color="#BF0303"
          background="#ffffff">
            Contact Us
          </PrimaryButton>
        </VerificationBadge>);
      }
      if (onlineAccountRegistrationStatus === 0) {
        return (
          <VerificationBadge
              backgroundColor="#e06900"
              icon={faExclamationTriangle}
              text="Complete your info in order to place a bid"
              header="Complete Online Account Registration Form"
            >
              <CompleteNowButton
              onClick={handleCompleteNow}
              fontSize="0.8rem"
              color="#e06900"
              background="#ffffff"
              >
                Complete Now
              </CompleteNowButton>
          </VerificationBadge>
        );
      }

      // Else Return Error
      return (
        <VerificationBadge
            backgroundColor="#BF0303"
            icon={faExclamationTriangle}
            text="Please refresh the page. If the problem persists, please contact us."
            header="Error loading application status"
          >
            <PrimaryButton
          onClick={handleContactUs}
          fontSize="0.8rem"
          color="#BF0303"
          background="#ffffff">
            Contact Us
          </PrimaryButton>
        </VerificationBadge>
      );
    }
  }

  if (authenticatedUser.verificationStatus === "Verified") {
    return (
      <VerificationBadge
        backgroundColor="#5BC64E"
        icon={faUserCheck}
        text="Place a bid now!"
        header="Your ID has been verified"
      ></VerificationBadge>
    );
  }

  if (authenticatedUser.hasExceededMaxVerificationAttempts) {
    return <VerificationExceededBadge showButton={true} client={client} />;
  }

  const idVerificationFailed =
    authenticatedUser.verificationStatus === "Failed";

  return (
    <VerificationBadge
      backgroundColor="#e06900"
      icon={faExclamationTriangle}
      header={
        idVerificationFailed
          ? "ID verification failed"
          : "ID verification required"
      }
      text={
        idVerificationFailed
          ? "Please verify your identity again in order to place a bid"
          : "Verify your identity in order to place a bid"
      }
    >
      <PrimaryButton
        onClick={handleVerifyNow}
        fontSize="0.8rem"
        color="#e06900"
        background="#ffffff"
      >
        Verify Now
      </PrimaryButton>
    </VerificationBadge>
  );
};

export default memo(VerificationHeader);
