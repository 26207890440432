import { RootState } from "store";

export const offersSelector = (state: RootState) => state.offer.offers;

export const offersLoadingSelector = (state: RootState) =>
  state.offer.offersLoading;

export const offerDocumentLoadingSelector = (state: RootState) =>
  state.offer.offerDocumentLoading;

export const bidLinkLoadingSelector = (state: RootState) =>
  state.offer.bidLinkLoading;

export const bidLinkSelector = (state: RootState) => state.offer.bidLink;

export const saveBidActivitySuccessSelector = (state: RootState) =>
  state.offer.saveBidActivitySuccess;
