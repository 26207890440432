import { ChangeEventHandler, memo, useRef, useState } from "react";
import styled, { AnyStyledComponent } from "styled-components";
import InputError from "components/inputError/InputError";
import { PatternFormat } from "react-number-format";
import { Form, InputGroup, Overlay, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const shared = (props: { type: string }) => `
  border-color: transparent !important;
  border-width: 0;
  font-size: 0.9rem;
  padding: 0;
  padding-left: ${props.type === "file" ? "10px" : "14px"} !important;
  margin-right: 8px !important;
  line-height: 40px;
  border-radius: 10px;
  box-shadow: none !important;
  background-image: none !important;
`;

const StyledFormControl = styled(Form.Control)<{
  $isInvalid: boolean | undefined;
  type: string;
}>`
  ${shared}
`;

const StyledMaskFormControl = styled(
  PatternFormat as unknown as AnyStyledComponent
)<{
  $isInvalid: boolean | undefined;
  type: string;
}>`
  ${shared}
`;

const StyledInputGroup = styled(InputGroup)<{
  $isInvalid: boolean | undefined;
}>`
  border-color: ${(props) => (props.$isInvalid ? "red" : "#979797")} !important;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;

  > svg {
    margin-top: 13px;
    font-size: 1.4rem;
    margin-right: 13px;
    color: #979797;
    cursor: help;
  }
`;

interface InputProps {
  type: string;
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isTouched?: boolean;
  errorMessage?: string;
  mask?: string[] | string | undefined;
  format?: string;
  id?: string;
  tooltip?: string;
  maxLength?: number;
  inputRef?: any;
}

const Input = (props: InputProps) => {
  const {
    type,
    className,
    placeholder,
    value,
    onChange,
    isTouched,
    errorMessage,
    mask,
    format,
    id,
    tooltip,
    maxLength,
    inputRef,
  } = props;

  const showError = isTouched && errorMessage !== undefined;

  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <StyledInputGroup $isInvalid={showError}>
        {!format && (
          <StyledFormControl
            maxLength={maxLength}
            className={className}
            spellCheck={false}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            $isInvalid={showError}
            ref={inputRef}
          />
        )}
        {format && (
          <StyledMaskFormControl
            maxLength={maxLength}
            className={className + " form-control"}
            spellCheck={false}
            value={value}
            type="text"
            onChange={onChange}
            $isInvalid={showError}
            format={format}
            placeholder={placeholder}
            allowEmptyFormatting={mask !== undefined}
            id={id}
            mask={mask}
          ></StyledMaskFormControl>
        )}
        {tooltip && (
          <>
            <FontAwesomeIcon
              icon={faInfoCircle}
              ref={target}
              onClick={() => setShow(!show)}
            />
            <Overlay
              placement="bottom"
              target={target.current}
              show={show}
              onHide={() => setShow(false)}
              rootClose={true}
            >
              <Tooltip id={`tooltip-bottom`}>{tooltip}</Tooltip>
            </Overlay>
          </>
        )}
      </StyledInputGroup>
      <InputError visible={showError}>{errorMessage}</InputError>
    </>
  );
};

Input.defaultProps = {
  type: "text",
  errorMessage: undefined,
  isTouched: false,
  inputRef: undefined,
};

export default memo(Input);
