import { memo } from "react";
import styled from "styled-components";

const Label = styled.div<{ $labelFontWeight: string }>`
  font-size: 0.9rem;
  font-weight: ${(props) => props.$labelFontWeight};
  @media (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

const Content = styled.div`
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  flex: 1;
`;

interface LabeledContentProps {
  label: string;
  children?: any;
  labelFontWeight?: string;
}

const LabeledContent = (props: LabeledContentProps) => {
  const { labelFontWeight, label, children } = props;
  return (
    <>
      <Label $labelFontWeight={labelFontWeight ?? "500"}>{label}</Label>
      <Content>{children ?? "None"}</Content>
    </>
  );
};

export default memo(LabeledContent);
