import { FONT_SIZES } from "../../constants";
import { memo } from "react";
import styled from "styled-components";

interface CountDownSegmentProps {
  value: string;
  label?: string | undefined;
  color?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 7px;
  padding: 0;
`;

const Label = styled.div`
  font-size: ${FONT_SIZES.Small};
  line-height: $ ${FONT_SIZES.Small};
  height: 0.8rem;
`;

const Value = styled.div<{ $color: string }>`
  color: ${(props) => props.$color};
  font-size: ${FONT_SIZES.ExtraLarge};
  line-height: ${FONT_SIZES.ExtraLarge};
`;

const CountDownSegment = (props: CountDownSegmentProps) => {
  const { value, color, label } = props;

  return (
    <Wrapper>
      <Value $color={color ?? "#5bc64e"}>{value}</Value>
      <Label>{label ?? " "}</Label>
    </Wrapper>
  );
};

export default memo(CountDownSegment);
