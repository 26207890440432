import { useAppDispatch, useAppSelector } from "hooks";
import Modal from "../modal/Modal";
import { RootState } from "store";
import { uiSlice } from "reducers/uiSlice";
import { contactUsModalVisibleSelector } from "selectors/uiSelectors";
import ModalNotice from "components/modalNotice/ModalNotice";
import { clientSelector } from "selectors/clientSelectors";

const ContactUsModal = () => {
    const dispatch = useAppDispatch();

    const client = useAppSelector((state: RootState) => clientSelector(state));

    const onCancel = () => {
        dispatch(uiSlice.actions.showContactUsModal(false));    
    };
    
    const contactUsModalVisible = useAppSelector((state: RootState) =>
        contactUsModalVisibleSelector(state)
    );

    return (
        <Modal
          title="Contact Us"
          onCancel={onCancel}
          visible={contactUsModalVisible}
        >
            <ModalNotice className="mb-4" align="center">
                
                Please contact us on
                <br /><br />
                <b style={{ fontSize: "1rem", fontWeight: 500 }}>
                {client?.contactEmail}
                </b>
            </ModalNotice>
        </Modal>
    );
}

export default ContactUsModal;