import { memo } from "react";
import * as moment from "moment-timezone";
import CountDownSegment from "../countDownSegment/CountDownSegment";

interface CountDownProps {
  remaining: moment.Duration;
  color: string;
}

const CountDown = (props: CountDownProps) => {
  const { remaining, color } = props;

  let days = remaining.days();
  if (days < 0) days = 0;
  let hours = remaining.hours();
  if (hours < 0) hours = 0;
  let minutes = remaining.minutes();
  if (minutes < 0) minutes = 0;

  return (
    <>
      <CountDownSegment
        color={color}
        value={days.toString().padStart(2, "0")}
        label="Days"
      />
      <CountDownSegment color={color} value={":"} />
      <CountDownSegment
        color={color}
        value={hours.toString().padStart(2, "0")}
        label="Hours"
      />
      <CountDownSegment color={color} value={":"} />
      <CountDownSegment
        color={color}
        value={minutes.toString().padStart(2, "0")}
        label="Mins"
      />
    </>
  );
};

export default memo(CountDown);
