import { memo } from "react";
import { Modal } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import styled from "styled-components";

const Wrapper = styled(Modal.Header)<{ $background: string }>`
  background: ${(props) => props.$background};
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 20px 20px 0px 0px;

  .modal-title {
    color: white;
    font-size: 1.6rem;
    font-weight: 400;
  }

  @media (max-width: 440px) {
    .modal-title {
      font-size: 1.4rem;
    }
  }
`;

const CloseButton = styled(XCircleFill)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  color: white !important;
  :hover {
    color: black !important;
  }
  @media (max-width: 575px) {
    top: 10px;
    right: 10px;
  }
`;

interface ModalHeaderProps {
  title: string;
  background?: string;
  onCancel: () => void;
}

const ModalHeader = (props: ModalHeaderProps) => {
  return (
    <Wrapper $background={props.background ?? "gray"}>
      <CloseButton onClick={props.onCancel} />
      <Modal.Title>{props.title}</Modal.Title>
    </Wrapper>
  );
};

export default memo(ModalHeader);
