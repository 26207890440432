import { AuthenticatedUser } from "types/authenticatedUser";
import WholesaleWarningCell from "components/wholesaleWarningCell/WholesaleWarningCell";
import Company from "components/company/Company";
import Currency from "components/currency/Currency";
import DateTile from "../dateTile/DateTile";
import DetailsCell from "../detailsCell/DetailsCell";
import ClosesOnCell from "../closesOnCell/ClosesOnCell";
import styled from "styled-components";

import { GetClosureInformation, ToSydneyTime } from "utilities/time";

import { Offer } from "../../types/offer";
import { Client } from "types/client";
import { FONT_SIZES } from "../../constants";
import CountDownCell from "components/countDownCell/CountDownCell";

const Broker = styled.div`
  white-space: pre-wrap;
  font-size: ${FONT_SIZES.Medium};
  line-height: ${FONT_SIZES.Medium};
  text-transform: uppercase;
  word-break: break-word;
`;

const CompanyCell = styled.div`
  margin-top: 3px;
  text-transform: uppercase;
`;

const AsxCode = styled.div`
  font-weight: 400;
  font-size: ${FONT_SIZES.Medium}};
`;

const asxCodeSort = (
  a: string | null,
  b: string | null,
  _sender: any,
  _data1: any,
  _data2: any,
  order: any
) => {
  if (a === null && b === null) return 0;
  if (a === null) return order.dir;
  if (b === null) return -1 * order.dir;

  return b.localeCompare(a);
};

const closesOnSort = (p1: string, p2: string) => {
  const closureInformation1 = GetClosureInformation(p1);
  const closureInformation2 = GetClosureInformation(p2);

  if (closureInformation1.expired && !closureInformation2.expired) return 1;
  if (closureInformation2.expired && !closureInformation1.expired) return -1;

  if (closureInformation1.daysRemaining > closureInformation2.daysRemaining)
    return 1;
  if (closureInformation1.daysRemaining < closureInformation2.daysRemaining)
    return -1;

  return 0;
};

export const getColumns = (
  width: number,
  authenticatedUser: AuthenticatedUser | undefined,
  client: Client | undefined,
  handleSelectOffer: (offer: Offer) => void
) => {
  const brokerColumnVisible = client?.settings?.offers?.hideBrokerInformation
    ? false
    : width > 400;

  const showCurrencyCode = client?.settings?.theme?.showCurrency ?? false;

  const asxColumnVisible =
    brokerColumnVisible && width > 560 ? true : width > 570;

  return [
    !brokerColumnVisible
      ? { visible: false }
      : {
          name: "broker",
          header: "Broker",
          cellProps: { className: "pad-left" },
          headerProps: { className: "pad-left" },
          minWidth:
            width >= 1150 ? 160 : width > 440 ? 120 : width > 415 ? 100 : 75,
          defaultFlex: 1,
          colspan: (row: any) => {
            return (row.data.isWholesale &&
              authenticatedUser?.sophisticatedApplicationStatus !== "Approved" &&  
              !client?.settings.offers.alwaysShowWholesaleOffers)
              ? 2
              : 1;
          },
          render: (row: any) => {
            if (
              row.data.isWholesale &&
              authenticatedUser?.sophisticatedApplicationStatus !== "Approved" && 
              !client?.settings.offers.alwaysShowWholesaleOffers
            )
              return (
                <WholesaleWarningCell
                  primaryColorHex={client?.settings?.theme?.primaryColorHex}
                  collapsedView={width < 400}
                  titleFontSize={
                    width < 400 ? FONT_SIZES.Small : FONT_SIZES.Medium
                  }
                  authenticatedUser={authenticatedUser}
                />
              );
            return (
              <CompanyCell>
                <Broker>{row.data.broker}</Broker>
              </CompanyCell>
            );
          },
        },
    {
      name: "company",
      header: "Company",
      cellProps: { className: "pad-left" },
      headerProps: { className: "pad-left" },
      defaultFlex: 2,
      render: (row: any) => {
        if (
          !brokerColumnVisible &&
          row.data.isWholesale &&
          authenticatedUser?.sophisticatedApplicationStatus !== "Approved" &&
          !client?.settings.offers.alwaysShowWholesaleOffers
        )
          return (
            <WholesaleWarningCell
              primaryColorHex={client?.settings?.theme?.primaryColorHex}
              collapsedView={width < 400}
              titleFontSize={width < 400 ? FONT_SIZES.Small : FONT_SIZES.Medium}
              authenticatedUser={authenticatedUser}
            />
          );
        return (
          <CompanyCell>
            <Company
              hideSpacer={true}
              maxNameHeight={"52px"}
              logoSize={"60px"}
              name={row.data.company}
              nameLineHeight={FONT_SIZES.Medium}
              hideLogo={
                (client?.settings?.offers?.hideBrokerInformation === true && width <= 660) ||
                (client?.settings?.offers?.hideBrokerInformation === false && width <= 1200)
              }
              logoUrl={row.data.companyLogoUrl}
            />
            {row.data.asxCode && !asxColumnVisible && (
              <AsxCode style={{ marginTop: "2px" }}>
                {client?.settings?.theme?.tickerSymbol || 'ASX'}: {row.data.asxCode}
              </AsxCode>
            )}
          </CompanyCell>
        );
      },
    },
    {
      name: "asxCode",
      sort: asxCodeSort,
      header: client?.settings?.theme?.tickerSymbol || 'ASX',
      visible: asxColumnVisible,
      width: 65,
      cellProps: width < 705 ? { className: "pad-right" } : null,
      headerProps: width < 705 ? { className: "pad-right" } : null,
      textAlign: "start",
      render: (row: any) => {
        if (
          !row.data.isWholesale ||
          authenticatedUser?.sophisticatedApplicationStatus === "Approved" ||
          (client?.settings?.offers?.alwaysShowWholesaleOffers ?? false)
        ) {
          return <AsxCode>{row.data.asxCode}</AsxCode>;
        }
        return undefined;
      },
    },
    {
      name: "amount",
      header: "Raise",
      type: "number",
      visible: width >= 705,
      cellProps: width <= 660 ? { className: "pad-right" } : null,
      headerProps: width <= 660 ? { className: "pad-right" } : null,
      width: showCurrencyCode ? 100 : 60,
      textAlign: "end",
      render: (row: any) => {
        return <Currency showCode={showCurrencyCode} code={row.data.currencyCode} amount={row.data.amount} showCents={false} />;
      },
    },
    {
      name: "sharePrice",
      header: "Price" + (showCurrencyCode ? "" : " (" + (client?.settings?.theme?.currency || "AUD") + ")"),
      type: "number",
      width: 120,
      visible: width >= 800,
      textAlign: "end",
      cellProps: { className: "pad-right" },
      headerProps: { className: "pad-right" },
      render: (row: any) => {
        return <Currency showCode={showCurrencyCode} code={row.data.currencyCode} amount={row.data.sharePrice} />;
      },
    },
    {
      name: "availableOn",
      header: "Offer Open",
      width: 120,
      textAlign: "start",
      cellProps: { className: "pad-left" },
      headerProps: { className: "pad-left" },
      visible: width > 1095,
      render: (row: any) => {
        const availableOnSydneyConverted = ToSydneyTime(row.data.availableOn);
        return <DateTile date={availableOnSydneyConverted} />;
      },
    },
    {
      name: "closesOn",
      header: "Closing Date",
      sort: closesOnSort,
      textAlign: "start",
      cellProps: { className: "pad-left" },
      headerProps: { className: "pad-left" },
      width: width > 660 ? 120 : 108,
      render: (row: any) => {
        return (
          <ClosesOnCell
            textAlign={"left"}
            expiredFontSize={width > 660 ? FONT_SIZES.Large : FONT_SIZES.Medium}
            closesOn={row.data.closesOn}
            primaryColorHex={client?.settings?.theme?.primaryColorHex || "black"}
          />
        );
      },
    },
    {
      sortable: false,
      width: 145,
      visible:
        (client?.settings?.offers?.hideBrokerInformation && width > 1050) ||
        (!client?.settings?.offers?.hideBrokerInformation && width > 1095),
      textAlign: "center",
      render: (row: any) => {
        return (
          <CountDownCell
            closesOn={row.data.closesOn}
            primaryColorHex={client?.settings?.theme?.primaryColorHex || "black"}
          />
        );
      },
    },
    {
      name: "id",
      header: width >= 820 ? "" : "Price",
      width: width >= 820 ? 125 : width > 660 ? 100 : 80,
      sortable: false,
      headerAlign: "center",
      render: (row: any) => {
        return (
          <DetailsCell
            buttonFontSize={width > 660 ? FONT_SIZES.Large : FONT_SIZES.Medium}
            authenticatedUser={authenticatedUser}
            data={row.data}
            showCollapsedView={width < 820}
            setSelectedOffer={handleSelectOffer}
            primaryColorHex={client?.settings?.theme?.primaryColorHex || "black"}
          />
        );
      },
    },
  ];
};
