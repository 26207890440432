import { memo } from "react";
import styled from "styled-components";
import { Alert } from "react-bootstrap";

const StyledAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1.5em;
  padding-bottom: 0.25em;
`;

interface FormErrorProps {
  message?: string;
  className?: string;
}

const FormError = (props: FormErrorProps) => {
  const { message, className } = props;

  if (!message) return <></>;

  return (
    <StyledAlert className={className} variant={"danger"}>
      {message.split("\r\n").map((e, _index) => (
        <p key={`error_${_index}`}>{e}</p>
      ))}
    </StyledAlert>
  );
};

export default memo(FormError);
