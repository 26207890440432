import moment from "moment-timezone";

const SydneyTimeZoneName = "Australia/Sydney";

export const GetSydneyTime = () => {
  return moment().tz(SydneyTimeZoneName);
};

export const ToSydneyTime = (input: string) => {
  const sydneyTimeNow = GetSydneyTime();
  const timezone = moment.parseZone(input);
  if (!sydneyTimeNow.isDST()) return timezone;
  return timezone.tz(SydneyTimeZoneName);
};

export const GetClosureInformation = (input: string) => {
  const sydneyTimeNow = GetSydneyTime();
  const closesOnSydneyConverted = ToSydneyTime(input);
  const remaining = moment.duration(
    closesOnSydneyConverted.diff(sydneyTimeNow)
  );
  var daysRemaining = remaining.asDays();
  const expired = daysRemaining < 0;
  return { closesOnSydneyConverted, remaining, daysRemaining, expired };
};
