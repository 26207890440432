import { memo } from "react";
import { Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { LightenDarkenColor } from "lighten-darken-color";

interface PrimaryButtonProps {
  background?: string;
  color: string;
  fontSize?: string;
  type?: string;
  children: any;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
  spinnerSize?: "sm";
  borderColor?: string;
}

const StyledButton = styled(Button)<{
  $background: string;
  $color: string;
  $fontSize: string;
  $borderColor: string;
  $hoverBackground: string;
  $hoverColor: string;
  $hoverBorderColor: string;
}>`
  background: ${(props) => props.$background} !important;
  color: ${(props) => props.$color} !important;
  font-size: ${(props) => props.$fontSize} !important;
  border: solid 2px ${(props) => props.$borderColor} !important;
  box-shadow: none !important;
  padding: 10px 20px 12px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .spinner-border {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  :hover {
    background: ${(props) => props.$hoverBackground} !important;
    color: ${(props) => props.$hoverColor} !important;
    border: solid 2px ${(props) => props.$hoverBorderColor} !important;
  }
`;

const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    className,
    disabled,
    background,
    fontSize,
    color,
    type,
    onClick,
    loading,
    borderColor,
    children,
    spinnerSize,
  } = props;

  return (
    <StyledButton
      $borderColor={borderColor}
      disabled={disabled}
      $background={background}
      $hoverBackground={LightenDarkenColor(background, -50)}
      $hoverColor={LightenDarkenColor(color, -50)}
      $hoverBorderColor={LightenDarkenColor(color, -50)}
      $fontSize={fontSize}
      $color={color}
      type={type}
      onClick={onClick}
      className={className}
    >
      
      {loading === true && <Spinner animation="border" size={spinnerSize} />}
      {loading !== true && (children as string).split('\\n').map( (text, i) => (
                      <span key={i}>{text}</span>))}
    </StyledButton>
  );
};

StyledButton.defaultProps = {
  background: "blue",
  border: "none",
  fontSize: "1.2rem",
  color: "white",
  type: "button",
  disabled: false,
};

export default memo(PrimaryButton);
