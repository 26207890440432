import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetOfferDocument } from "types/getOfferDocument";
import { GetBidLink } from "types/getBidLink";
import { GetBidLinkResponse } from "types/getBidLinkResponse";
import { SaveBidActivity } from "types/saveBidActivity";
import { Offer } from "types/offer";

interface OfferState {
  offersLoading: boolean;
  offers: Offer[] | null;
  offerDocumentLoading: number | undefined;
  bidLink: string | undefined;
  bidLinkLoading: boolean;
  savingBidActivity: boolean;
  saveBidActivitySuccess: boolean;
  saveBidActivityError?: string;
}

const initialState: OfferState = {
  offersLoading: false,
  offers: null,
  offerDocumentLoading: undefined,
  bidLinkLoading: false,
  bidLink: undefined,
  savingBidActivity: false,
  saveBidActivitySuccess: false,
  saveBidActivityError: undefined,
};

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    loadOffers: (state) => {
      state.offersLoading = true;
    },
    loadOffersSuccess: (state, action: PayloadAction<Offer[]>) => {
      state.offersLoading = false;
      state.offers = action.payload;
    },
    loadOffersFailed: (state) => {
      state.offersLoading = false;
    },
    loadBidLink: (state, _action: PayloadAction<GetBidLink>) => {
      state.bidLinkLoading = true;
      state.bidLink = undefined;
    },
    loadBidLinkSuccess: (state, action: PayloadAction<GetBidLinkResponse>) => {
      state.bidLinkLoading = false;
      state.bidLink = action.payload.url;
    },
    loadBidLinkFailed: (state) => {
      state.bidLinkLoading = false;
      state.bidLink = undefined;
    },
    loadOfferDocument: (state, action: PayloadAction<GetOfferDocument>) => {
      state.offerDocumentLoading = action.payload.offerDocumentId;
    },
    loadOfferDocumentSuccess: (state) => {
      state.offerDocumentLoading = undefined;
    },
    loadOfferDocumentFailed: (state) => {
      state.offerDocumentLoading = undefined;
    },
    clearBidLink: (state) => {
      state.bidLink = undefined;
      state.saveBidActivitySuccess = false;
      state.saveBidActivityError = undefined;
    },
    saveBidActivity: (state, _action: PayloadAction<SaveBidActivity>) => {
      state.savingBidActivity = true;
      state.saveBidActivitySuccess = false;
      state.saveBidActivityError = undefined;
    },
    saveBidActivitySuccess: (state) => {
      state.savingBidActivity = false;
      state.saveBidActivitySuccess = true;
    },
    saveBidActivityFailed: (state, action: PayloadAction<string>) => {
      state.savingBidActivity = false;
      state.saveBidActivityError = action.payload;
    },
  },
});

export const { loadOffersSuccess } = offerSlice.actions;

export const { loadOffersFailed } = offerSlice.actions;

export const { loadOfferDocumentSuccess } = offerSlice.actions;

export const { loadOfferDocumentFailed } = offerSlice.actions;

export const { loadBidLinkSuccess } = offerSlice.actions;

export const { loadBidLinkFailed } = offerSlice.actions;

export const { saveBidActivitySuccess } = offerSlice.actions;

export const { saveBidActivityFailed } = offerSlice.actions;

export default offerSlice.reducer;
