import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "../types/client";

interface ClientState {
  loadingClient: boolean;
  client: Client | undefined;
  loadClientError: string | undefined;
}

const initialState: ClientState = {
  loadingClient: false,
  client: undefined,
  loadClientError: undefined,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    loadClient: (state) => {
      state.loadingClient = true;
      state.client = undefined;
    },
    loadClientSuccess: (state, action: PayloadAction<Client>) => {
      state.loadingClient = false;
      state.client = action.payload;
    },
    loadClientFailed: (state, action: PayloadAction<string>) => {
      state.loadingClient = false;
      state.client = undefined;
      state.loadClientError = action.payload;
    },
  },
});

export const { loadClientSuccess } = clientSlice.actions;
export const { loadClientFailed } = clientSlice.actions;

export default clientSlice.reducer;
