import * as Yup from "yup";
import { memo, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import { ChangePassword } from "../../types/changePassword";
import { RootState } from "store";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { clientSelector } from "selectors/clientSelectors";
import Input from "components/input/Input";
import {
  changePasswordErrorSelector,
  changePasswordSuccessSelector,
  changingPasswordSelector,
} from "selectors/userSelectors";
import { userSlice } from "reducers/userSlice";
import ModalNotice from "components/modalNotice/ModalNotice";
import { uiSlice } from "reducers/uiSlice";
import { toastr } from "react-redux-toastr";
import FormError from "components/formError/FormError";

const PasswordResetStep2Form = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const changingPassword = useAppSelector((state: RootState) =>
    changingPasswordSelector(state)
  );

  const changePasswordSuccess = useAppSelector((state: RootState) =>
    changePasswordSuccessSelector(state)
  );

  const changePasswordError = useAppSelector((state: RootState) =>
    changePasswordErrorSelector(state)
  );

  const schema = Yup.object({
    code: Yup.string().required("8 Character Code is a required field."),
    password: Yup.string().required("Password is a required field."),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      code: "",
      password: "",
    },
    onSubmit: (values: any) => {
      const changePassword: ChangePassword = {
        ...values,
      };
      dispatch(userSlice.actions.changePassword(changePassword));
    },
  });

  useEffect(() => {
    if (changePasswordSuccess) {
      toastr.info(
        "Password Changed",
        "Your password has been changed you can now sign in with it"
      );
      formik.resetForm();
      dispatch(uiSlice.actions.showPasswordResetModal(false));
      dispatch(uiSlice.actions.showSignInModal(true));
    }
  }, [dispatch, formik, changePasswordSuccess]);

  return (
    <Form onSubmit={formik.handleSubmit} autoComplete="off">
      <Container>
        <ModalNotice className="mb-4">
          Please enter the 8 character code sent to your email and a new
          password.
        </ModalNotice>
        <Form.Group className="mb-4" controlId="code">
          <Input
            type="text"
            placeholder="8 Character Code"
            value={formik.values.code}
            onChange={formik.handleChange}
            isTouched={formik.touched.code}
            errorMessage={formik.errors.code}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="password">
          <Input
            type="password"
            placeholder="New Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            isTouched={formik.touched.password}
            errorMessage={formik.errors.password}
          />
        </Form.Group>
        <FormError message={changePasswordError} />
        <PrimaryButton
          color="white"
          background={client?.settings?.theme?.primaryColorHex}
          type="submit"
          disabled={changingPassword}
          loading={changingPassword}
        >
          Save
        </PrimaryButton>
      </Container>
    </Form>
  );
};

export default memo(PasswordResetStep2Form);
