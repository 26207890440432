import { FONT_SIZES } from "../../constants";
import { memo } from "react";
import styled from "styled-components";

interface CompanyProps {
  name: string;
  logoUrl: string;
  nameFontSize?: string;
  nameFontWeight?: string;
  nameLineHeight?: string;
  logoSize?: string;
  maxNameHeight?: string;
  tickerSymbol?: string;
  asxCode?: string;
  hideLogo?: boolean;
  hideSpacer?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  max-height: 105px;
`;

const CompanyName = styled.div<{
  $fontSize: string;
  $fontWeight: string;
  $lineHeight: string;
  $maxHeight: string;
}>`
  font-size: ${(props: any) => props.$fontSize};
  white-space: pre-wrap;
  max-height: ${(props: any) => props.$maxHeight};
  word-break: break-word;
  display: flex;
  font-weight: ${(props: any) => props.$fontWeight};
  line-height: ${(props: any) => props.$lineHeight};
  overflow: hidden;
  align-items: start;
}
`;

const CompanyLogo = styled.img<{ $logoSize: string; $marginRight: string }>`
  max-height: ${(props: any) => props.$logoSize};
  max-width: ${(props: any) => props.$logoSize};
  margin-right: ${(props: any) => props.$marginRight};
  align-self: center;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AsxCode = styled.div`
  font-size: ${FONT_SIZES.Medium};
  font-weight: 400;
  margin-top: -3px;
`;

const CompanyNameWrapper = styled.div<{ _showBorder: boolean }>`
  border-left: ${(props) => (props._showBorder ? "solid 1px #888888" : "none")};
  padding-left: ${(props) => (props._showBorder ? "20px" : "0px")};
`;

const Company = (props: CompanyProps) => {
  const {
    name,
    logoUrl,
    nameFontSize = FONT_SIZES.Medium,
    nameFontWeight = "700",
    nameLineHeight = "initial",
    logoSize = "100px",
    maxNameHeight = "auto",
    tickerSymbol,
    asxCode,
    hideLogo = false,
    hideSpacer = false,
  } = props;

  return (
    <Wrapper>
      {hideLogo === false && (
        <CompanyLogo
          $marginRight={hideSpacer ? "15px" : "20px"}
          src={logoUrl ?? "blankCompany.png"}
          alt={name}
          $logoSize={logoSize}
        />
      )}
      <Right>
        <CompanyNameWrapper _showBorder={!hideSpacer}>
          <CompanyName
            $lineHeight={nameLineHeight}
            $fontSize={nameFontSize}
            $fontWeight={nameFontWeight}
            $maxHeight={maxNameHeight}
          >
            {name}
          </CompanyName>
          {asxCode && <AsxCode>{tickerSymbol}: {asxCode}</AsxCode>}
        </CompanyNameWrapper>
      </Right>
    </Wrapper>
  );
};

export default memo(Company);
