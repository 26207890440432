import { memo } from "react";
import { useAppSelector } from "hooks";
import { RootState } from "store";
import { Container } from "react-bootstrap";
import FormError from "components/formError/FormError";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import Input from "components/input/Input";
import * as Yup from "yup";
import { clientSelector } from "selectors/clientSelectors";
import { Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { authenticatedUserSelector } from "selectors/userSelectors";

interface BankAccountFormProps {
  submitButtonText?: string;
  handleSubmit: (values: any) => void;
  error?: string;
  busy?: boolean;
  className?: string;
}

const bsbRegex = RegExp("^\\d{3}\\-\\d{3}$");

const accountRegex = RegExp("^\\d{8,12}[_]{0,4}$");

const BankAccountForm = (props: BankAccountFormProps) => {
  const { submitButtonText, error, busy, className, handleSubmit } = props;

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );

  const schema = Yup.object({
    financialInstituteName: Yup.string().required(
      "Financial Institute Name is a required field."
    ),
    accountName: Yup.string().required("Account Name is a required field."),
    bsb: Yup.string()
      .required("BSB is a required field.")
      .matches(bsbRegex, "BSB must be in the format 111-111."),
    accountNumber: Yup.string()
      .required("Account Number is a required field.")
      .matches(accountRegex, "Account Number must be 8-12 digits."),
  });

  const hasSetBankAccount =
    authenticatedUser?.bankAccountNumberLastDigits !== null;

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      financialInstituteName: hasSetBankAccount
        ? authenticatedUser?.financialInstituteName
        : undefined,
      accountName: "",
      bsb: "",
      accountNumber: "",
    },
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const getMask = (currentValue: string) => {
    if (hasSetBankAccount) {
      return currentValue !== "" && currentValue[0] !== "_" ? "_" : undefined;
    }
    return "_";
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className={className}
    >
      <Container className="px-0">
        <Form.Group
          as={Row}
          className="mb-4"
          controlId="financialInstituteName"
        >
          <Form.Label column sm="4">
            Financial Institute Name
          </Form.Label>
          <Col sm="8">
            <Input
              type="text"
              value={formik.values.financialInstituteName}
              onChange={formik.handleChange}
              isTouched={formik.touched.financialInstituteName}
              errorMessage={formik.errors.financialInstituteName}
              id="financialInstituteName"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-4" controlId="accountName">
          <Form.Label column sm="4">
            Account Name
          </Form.Label>
          <Col sm="8">
            <Input
              type="text"
              value={formik.values.accountName}
              onChange={formik.handleChange}
              isTouched={formik.touched.accountName}
              errorMessage={formik.errors.accountName}
              id="accountName"
              placeholder={hasSetBankAccount ? "****" : ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-4" controlId="bsb">
          <Form.Label column sm="4">
            BSB
          </Form.Label>
          <Col sm="8">
            <Input
              type="text"
              value={formik.values.bsb}
              onChange={formik.handleChange}
              isTouched={formik.touched.bsb}
              errorMessage={formik.errors.bsb}
              format="###-###"
              mask={getMask(formik.values.bsb)}
              id="bsb"
              placeholder={hasSetBankAccount ? "***-***" : "_"}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-4" controlId="accountNumber">
          <Form.Label column sm="4">
            Account Number
          </Form.Label>
          <Col sm="8">
            <Input
              type="text"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              isTouched={formik.touched.accountNumber}
              errorMessage={formik.errors.accountNumber}
              format="###########"
              mask={getMask(formik.values.accountNumber)}
              id="accountNumber"
              placeholder={
                hasSetBankAccount
                  ? "*********" + authenticatedUser?.bankAccountNumberLastDigits
                  : undefined
              }
            />
          </Col>
        </Form.Group>
        <FormError message={error} />
        <PrimaryButton
          color="white"
          background={client?.settings?.theme?.primaryColorHex}
          type="submit"
          disabled={busy}
          loading={busy}
        >
          {submitButtonText ?? "Submit"}
        </PrimaryButton>
      </Container>
    </Form>
  );
};

export default memo(BankAccountForm);
