import { memo, useCallback, useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

interface IFrameModalProps {
  onClose: () => void;
  url: string;
  title: string;
  windowMessageType?: string;
  windowMessageCallback?: (payload: any) => void;
}

const Wrapper = styled.div`
  padding: 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100px;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  padding: 0 !important;
  height: calc(100vh - 156px);
  @media (max-width: 575px) {
    height: calc(100vh - 162px);
  }
`;

const IFrameModal = (props: IFrameModalProps) => {
  const [loading, setLoading] = useState(true);
  const [windowMessagePayload, setWindowMessagePayload] = useState(undefined);
  const { url, onClose, title, windowMessageType, windowMessageCallback } =
    props;

  const eventListener = useCallback(
    (event: any) => {
      console.info(`Got window message ${JSON.stringify(event.data)}`);

      if (!windowMessageType) return;

      if (event.data?.messageType !== windowMessageType) {
        console.warn(
          `Invalid payload expected payload.messageType = ${windowMessageType}`
        );
        return;
      }

      setWindowMessagePayload(event.data);
    },
    [windowMessageType]
  );

  useEffect(() => {
    if (!loading) {
      window.addEventListener("message", eventListener);
    }
  }, [eventListener, loading]);

  const handleClose = () => {
    window.removeEventListener("message", eventListener);
    onClose();
  };

  useEffect(() => {
    if (windowMessagePayload && windowMessageCallback) {
      window.removeEventListener("message", eventListener);
      windowMessageCallback(windowMessagePayload);
    }
  }, [eventListener, windowMessageCallback, windowMessagePayload]);

  return (
    <Modal
      large={true}
      title={title}
      onCancel={handleClose}
      visible={true}
      bodyPadding="0"
    >
      <Wrapper>
        {loading ? <Spinner animation="border" /> : null}
        <StyledIFrame
          style={{ display: loading ? "none" : "block" }}
          src={url}
          onLoad={() => setLoading(false)}
        ></StyledIFrame>
      </Wrapper>
    </Modal>
  );
};

export default memo(IFrameModal);
