import { configureStore } from "@reduxjs/toolkit";
import userReducer from "reducers/userSlice";
import uiReducer from "reducers/uiSlice";
import offerReducer from "reducers/offerSlice";
import clientReducer from "reducers/clientSlice";
import createSagaMiddleware from "redux-saga";
import offerSagas from "sagas/offerSagas";
import clientSagas from "sagas/clientSagas";
import userSagas from "sagas/userSagas";
import { reducer as toastrReducer } from "react-redux-toastr";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    user: userReducer,
    ui: uiReducer,
    client: clientReducer,
    offer: offerReducer,
    toastr: toastrReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(offerSagas);
sagaMiddleware.run(userSagas);
sagaMiddleware.run(clientSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
