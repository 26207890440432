import { useAppSelector } from "hooks";
import { memo } from "react";
import { clientSelector } from "selectors/clientSelectors";
import { RootState } from "store";
import styled from "styled-components";
import { GetSydneyTime } from "utilities/time";

const Wrapper = styled.div<{ $background: string }>`
  background: ${(props) => props.$background};
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 10px;
  @media (max-width: 610px) {
    padding: 10px 10px 0 0;
  }
`;

const PlacementsLogo = styled.img`
  width: 100px;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: end;
`;

const Times = styled.div`
  text-align: right;
  color: white;
  flex: 1;
  font-size: 0.8rem;
  padding: 0 15px 10px 0;
  @media (max-width: 608px) {
    padding: 0 2px 5px 0;
    font-size: 0.7rem;
  }
`;

const GridFooter = () => {
  const client = useAppSelector((state: RootState) => clientSelector(state));

  const sydnetTimeNow = GetSydneyTime();
  
  return (
    <Wrapper $background={client?.settings?.theme?.footerColorHex || client?.settings?.theme?.primaryColorHex || "black"}>
      <PlacementsLogo alt="Placements Logo" src={client?.settings?.theme?.placementsLogo || "placementsLogo.svg" } />
      <Right>
        {!client?.settings?.theme?.hideTimezoneWarning && ( 
        <Times>
          {sydnetTimeNow.isDST()
            ? "All times are Australian Eastern Daylight Time (AEDT)"
            : "All times are Australian Eastern Standard Time (AEST)"}
        </Times>
        )}
      </Right>
    </Wrapper>
  );
};

export default memo(GridFooter);
