import { memo } from "react";
import { Offer } from "types/offer";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { displayNameSelector } from "selectors/userSelectors";
import { RootState } from "store";
import { OfferDocument } from "types/offerDocument";
import { offerSlice } from "reducers/offerSlice";
import { offerDocumentLoadingSelector } from "selectors/offerSelectors";
import { Spinner } from "react-bootstrap";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { FONT_SIZES } from "../../constants";

interface OfferDocumentsProps {
  offer: Offer;
  onSignInToView: () => void;
  primaryColorHex?: string;
}

const maxFileNameLength = 40;

const File = styled.div`
  font-size: 0.8rem;
  word-break: break-word;
  color: #0051f2;
  text-decoration: underline;
  cursor: pointer;
  padding: 5px 0 5px;
`;

const LoginButton = styled(PrimaryButton)`
  padding: 5px 0 6px;
  margin-top: 10px;
  font-weight: 500;
`;

const LoginButtonWrapper = styled.div`
  padding-right: 15px;
`;

const None = styled.div`
  font-size: 0.9rem;
  margin-top: 5px;
  font-weight: 400;
`;

const OfferDocumentsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;

  > div:nth-child(even) {
    background: #f0f0f0;
  }
`;

const OfferDocuments = (props: OfferDocumentsProps) => {
  const { offer, onSignInToView, primaryColorHex } = props;

  const dispatch = useAppDispatch();

  const offerDocumentLoading = useAppSelector((state: RootState) =>
    offerDocumentLoadingSelector(state)
  );

  const displayName = useAppSelector((state: RootState) =>
    displayNameSelector(state)
  );

  if (!displayName)
    return (
      <LoginButtonWrapper>
        <LoginButton
          fontSize={FONT_SIZES.Medium}
          background="transparent"
          color={primaryColorHex || "black"}
          borderColor={primaryColorHex || "black"}
          onClick={onSignInToView}
        >
          Login To View
        </LoginButton>
      </LoginButtonWrapper>
    );

  if (!offer.offerDocuments || offer.offerDocuments.length === 0)
    return <None>None</None>;

  const fetchFile = (od: OfferDocument) => {
    if (offerDocumentLoading) return;

    if (od.fileLink) {
      window.open(od.fileLink, "_blank");
      return;
    }

    dispatch(
      offerSlice.actions.loadOfferDocument({
        offerDocumentId: od.offerDocumentId,
        offerId: offer.id,
        fileName: od.fileName,
      })
    );
  };

  const trimFileName = (fileName: string) => {
    if (fileName.length > maxFileNameLength) {
      return fileName.substring(0, maxFileNameLength) + "...";
    }
    return fileName;
  };

  return (
    <OfferDocumentsWrapper>
      {offer.offerDocuments.map((od: OfferDocument) => {
        return (
          <File key={od.offerDocumentId} onClick={() => fetchFile(od)}>
            {trimFileName(od.fileName)}{" "}
            {offerDocumentLoading === od.offerDocumentId && (
              <Spinner color="#0051f2" animation="border" size="sm" />
            )}
          </File>
        );
      })}
    </OfferDocumentsWrapper>
  );
};

export default memo(OfferDocuments);
