import { memo } from "react";
import styled from "styled-components";

const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
`;

interface InputProps {
  visible?: boolean | undefined;
  children: any;
}

const InputError = (props: InputProps) => {
  if (!props.visible) return <></>;
  return <Error>{props.children}</Error>;
};

InputError.defaultProps = {
  visible: false,
};

export default memo(InputError);
