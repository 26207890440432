import { memo } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import VerificationBadge from "components/verificationBadge/VerificationBadge";
import { Client } from "types/client";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { emailUs } from "utilities/contact";
import LinkButton from "components/linkButton/LinkButton";
import styled from "styled-components";

interface VerificationExceededBadgeProps {
  showButton?: boolean;
  client?: Client;
  className?: string;
  header?: string;
}

const EmailLink = styled(LinkButton)`
  color: white !important;
  font-size: 0.9rem;
  display: inline;
  min-height: auto;
  width: auto;
  padding: 0 2px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: -3px;
`;

const Text = styled.div`
  display: block;
`;

const VerificationExceededBadge = (props: VerificationExceededBadgeProps) => {
  const { showButton, client, className, header } = props;

  const generateText = () => {
    return (
      <Text>
        Please email{" "}
        <EmailLink onClick={handleEmailUs}>
          {client?.contactEmail ?? "[no client email set]"}
        </EmailLink>
        to resolve
      </Text>
    );
  };

  const handleEmailUs = () => {
    emailUs(client?.contactEmail);
  };

  return (
    <VerificationBadge
      className={className}
      backgroundColor="#BF0303"
      header={header ?? "Problems verifying your ID"}
      text={generateText()}
      icon={faExclamationTriangle}
    >
      {showButton && (
        <PrimaryButton
          onClick={handleEmailUs}
          fontSize="0.8rem"
          color="#BF0303"
          background="#ffffff"
        >
          Email Us
        </PrimaryButton>
      )}
    </VerificationBadge>
  );
};

export default memo(VerificationExceededBadge);
