import InputError from "components/inputError/InputError";
import { memo } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import styled from "styled-components";

const Wrapper = styled.div<{ $isInvalid: boolean | undefined }>`
  input.form-control {
    border-color: ${(props) =>
      props.$isInvalid ? "red" : "#979797"} !important;
    font-size: 0.9rem;
    line-height: 30px;    
    border-radius: 10px;
    box-shadow: none !important;
  }
`;

interface AutoSuggestInputProps {
  id?: string;
  labelKey: string;
  errorMessage?: string;
  options: any[];
  isLoading: boolean;
  isTouched?: boolean;
  placeholder?: string;
  minLength?: number;
  onSearch: (query: string) => void;
  onChange: (value: any) => void;
}

const AutoSuggestInput = (props: AutoSuggestInputProps) => {
  const showError = props.isTouched && props.errorMessage !== undefined;
  return (
    <Wrapper $isInvalid={showError}>
      <AsyncTypeahead
        id={props.id}
        options={props.options}
        isLoading={props.isLoading}
        onSearch={props.onSearch}
        onChange={props.onChange}
        minLength={props.minLength}
        placeholder={props.placeholder}
        labelKey={(option: any) => `${option[props.labelKey]}`}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result: any, index) => (
              <MenuItem key={index} option={result} position={index}>
                {result[props.labelKey]}
              </MenuItem>
            ))}
          </Menu>
        )}
      />
      <InputError visible={showError}>{props.errorMessage}</InputError>
    </Wrapper>
  );
};

AutoSuggestInput.defaultProps = {
  isLoading: false,
  isTouched: false,
  options: [],
  errorMessage: undefined,
  placeholder: undefined,
};

export default memo(AutoSuggestInput);
