import { memo, useCallback, useEffect, useRef } from "react";
import Modal from "components/modal/Modal";
import { Col, Container, Form, Row } from "react-bootstrap";
import FormError from "components/formError/FormError";
import Input from "components/input/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import Select from "components/select/Select";
import ModalNotice from "components/modalNotice/ModalNotice";
import AutoSuggestInput from "components/autoSuggestInput/AutoSuggestInput";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { clientSelector } from "selectors/clientSelectors";
import { debounce } from "throttle-debounce";
import {
  authenticatedUserSelector,
  saveIdentityDataErrorSelector,
  saveIdentityDataSuccessSelector,
  saveIdentityErrorsSelector,
  savingIdentityDataSelector,
  validatedAddressesSelector,
  validatingAddressSelector,
} from "selectors/userSelectors";
import { SaveIdentityData } from "types/saveIdentityData";
import { userSlice } from "reducers/userSlice";
import { uiSlice } from "reducers/uiSlice";
import VerificationExceededBadge from "components/verificationExceededBadge/VerificationExceededBadge";
import styled from "styled-components";
import { SaveIdentityErrors } from "types/saveIdentityErrors";

const CardNumberLocations = styled.div`
  margin: -10px 3px 25px -3px;
  display: flex;
  > img {
    width: 48%;
    border-radius: 15px;
    margin: 0 6px;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
  }
`;

const StyledFormLabel = styled(Form.Label)`
  font-size: 0.9rem;
  font-weight: bold;
`;

const StyledFormCheck = styled(Form.Check)`
  font-size: 0.9rem;
  font-weight: bold;
`;

const FormErrorCenter = styled(FormError)`
  font-size: 0.9rem;
  text-align: center;
  font-weight: normal;
`;

interface IdentityDocumentModalProps {
  onClose: () => void;
}

const statesWithCardNumber = [
  { name: "NSW", images: ["cn-nsw.png"] },
  { name: "ACT", images: ["cn-act.png"] },
  { name: "SA", images: ["cn-sa.png"] },
  { name: "TAS", images: ["cn-tas.png"] },
  { name: "WA", images: ["cn-wa.png"] },
  { name: "QLD", images: ["cn-qld.png", "cn-qld-2.png"] },
  { name: "VIC", images: ["cn-vic.png", "cn-vic-2.png"] },
  { name: "NT", images: ["cn-nt.png", "cn-nt-2.png"] },
];

const statesWithLicenseNumber = [
  { name: "NSW", images: ["ln-nsw.png"] },
  { name: "ACT", images: ["ln-act.png"] },
  { name: "SA", images: ["ln-sa.png"] },
  { name: "TAS", images: ["ln-tas.png"] },
  { name: "WA", images: ["ln-wa.png"] },
  { name: "QLD", images: ["ln-qld.png"] },
  { name: "VIC", images: ["ln-vic.png"] },
  { name: "NT", images: ["ln-nt.png"] },
];

enum IdentityTypes {
  DriversLicense,
  Passport,
}

const dateRegex = RegExp(
  "^(0[1-9]|[12][0-9]|3[01])\\-(0[1-9]|1[012])\\-[0-9]{4}$"
);

const IdentityDocumentModal = (props: IdentityDocumentModalProps) => {
  const { onClose } = props;
  // const [showCardNumberLocations, setShowCardNumberLocations] = useState(false);
  // const [showLicenseNumberLocations, setShowLicenseNumberLocations] = useState(false);
  const dispatch = useAppDispatch();
  const client = useAppSelector((state: RootState) => clientSelector(state));
  const savingIdentityData = useAppSelector((state: RootState) =>
    savingIdentityDataSelector(state)
  );
  const saveIdentityDataSuccess = useAppSelector((state: RootState) =>
    saveIdentityDataSuccessSelector(state)
  );
  const saveIdentityDataError = useAppSelector((state: RootState) =>
    saveIdentityDataErrorSelector(state)
  );
  const validatingAddress = useAppSelector((state: RootState) =>
    validatingAddressSelector(state)
  );
  const validatedAddresses = useAppSelector((state: RootState) =>
    validatedAddressesSelector(state)
  );
  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );
  const saveIdentityErrors = useAppSelector((state: RootState) =>
    saveIdentityErrorsSelector(state)
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const getFieldName = (identityTypes: IdentityTypes, postFix: string) => {
    return `${
      identityTypes === IdentityTypes.DriversLicense ? "Licence" : "Passport"
    } ${postFix}`;
  };

  const schema = Yup.object({
    identityNumber: Yup.lazy(() => {
      const fieldName = getFieldName(formik.values.identityType, "Number");
      return Yup.string().required(`${fieldName} is a required field.`);
    }),
    rtaNumber: Yup.lazy(() => {
      if (
        formik.values.identityType === IdentityTypes.DriversLicense &&
        statesWithCardNumber?.some((s) => s.name === formik.values.licenseState)
      ) {
        return Yup.string().required("Card Number is a required field.");
      }
      return Yup.string();
    }),
    licenseState: Yup.lazy(() => {
      if (formik.values.identityType === IdentityTypes.DriversLicense) {
        return Yup.string().required("Licence State is a required field.");
      }
      return Yup.string();
    }),
    licenseExpiry: Yup.lazy(() => {
      if (formik.values.identityType === IdentityTypes.DriversLicense) {
        return Yup.string()
          .required("Licence Expiry is a required field.")
          .matches(
            dateRegex,
            "Licence Expiry must be in the format DD-MM-YYYY."
          );
      }
      return Yup.string();
    }),
    dateOfBirth: Yup.string()
      .required("Date Of Birth is a required field.")
      .matches(dateRegex, "Date Of Birth must be in the format DD-MM-YYYY."),
    address: Yup.object().required("Address is a required field."),
    idDocument: Yup.lazy(() => {
      const fieldName = getFieldName(formik.values.identityType, "Photo");
      return Yup.mixed().required(`${fieldName} is a required field.`);
    }),
  });

  const initialValues = {
    identityNumber: "",
    licenseState: "",
    licenseExpiry: "",
    middleName: "",
    dateOfBirth: "",
    identityType: IdentityTypes.DriversLicense,
    idDocument: null,
    address: undefined,
    rtaNumber: "",
  };

  const formik = useFormik({
    validationSchema: schema,
    initialValues: initialValues,
    onSubmit: (values: any) => {
      const saveIdentityData: SaveIdentityData = {
        placeId: values.address?.placeId,
        ...values,
      };
      dispatch(userSlice.actions.saveIdentityData(saveIdentityData));
    },
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (saveIdentityDataSuccess || saveIdentityDataError) {
      dispatch(userSlice.actions.getAuthenticatedUser());
    }
  }, [saveIdentityDataSuccess, saveIdentityDataError, dispatch]);

  useEffect(() => {
    if (saveIdentityDataSuccess) {
      dispatch(uiSlice.actions.showIdentityDocumentSubmittedModal(true));
      formik.resetForm();
      handleClose();
    }
  }, [dispatch, formik, handleClose, saveIdentityDataSuccess]);

  const idDocumentHandleChange = (event: any) => {
    var file = event.currentTarget.files[0];
    formik.setFieldValue("idDocument", file);
  };

  const identityTypeChange = (identityType: IdentityTypes) => {
    formik.setFieldValue("identityType", identityType);
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    formik.setFieldValue("idDocument", initialValues.idDocument);
    formik.setFieldTouched("idDocument", false, false);
    formik.setFieldValue("identityNumber", initialValues.identityNumber);
    formik.setFieldTouched("identityNumber", false, false);
    formik.setFieldValue("licenseExpiry", initialValues.licenseExpiry);
    formik.setFieldTouched("licenseExpiry", false, false);
    formik.setFieldValue("licenseState", initialValues.licenseState);
    formik.setFieldTouched("licenseState", false, false);
    formik.setFieldValue("rtaNumber", initialValues.rtaNumber);
    formik.setFieldTouched("rtaNumber", false, false);
  };

  const handleSearch = debounce(600, (query) => {
    dispatch(userSlice.actions.validateAddress(query));
  });

  const dobError = formik.errors.dateOfBirth ?? (saveIdentityErrors as SaveIdentityErrors).DateOfBirth;
  const idNumberError = formik.errors.identityNumber ?? (saveIdentityErrors as SaveIdentityErrors).IdentityNumber;
  const licenseExpiryError = formik.errors.licenseExpiry ?? (saveIdentityErrors as SaveIdentityErrors).LicenseExpiry;

  return (
    <Modal title="ID Verification" onCancel={handleClose} visible={true} large={true}>
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Container>
          <ModalNotice weight="700" align="start" className="mb-3">
            Australian ID Verification
          </ModalNotice>
          <ModalNotice align="start" className="mb-2 d-block">
            You must verify your identity with an Australian Driver's Licence or a Passport to 
            participate in live deals. Verification is fast, and you can begin bidding on deals!
          </ModalNotice>
          <ModalNotice align="start" className="mb-4 d-block">
            Please check all your details before submitting, you will only have <strong>3 attempts</strong> in total.
          </ModalNotice>
          <Form.Group as={Row} className="mb-4" controlId="address" size="sm">
            <StyledFormLabel column sm="3">
              Address
            </StyledFormLabel>
            <Col sm="9">
              <AutoSuggestInput
                minLength={4}
                id="address"
                onSearch={handleSearch}
                labelKey="description"
                options={validatedAddresses}
                isLoading={validatingAddress}
                isTouched={formik.touched.address}
                errorMessage={formik.errors.address}
                onChange={(location) => {
                  formik.setFieldValue("address", location[0]);
                }}
                placeholder="Type your address"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="middleName">
            <StyledFormLabel column sm="3">
              Middle Name
            </StyledFormLabel>
            <Col sm="9">
              <Input
                type="text"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                isTouched={formik.touched.middleName}
                errorMessage={formik.errors.middleName}
                id="middleName"
                placeholder="Type your middle name"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="dateOfBirth">
            <StyledFormLabel column sm="3">
              Date Of Birth
            </StyledFormLabel>
            <Col sm="9">
              <Input
                type="text"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                isTouched={formik.touched.dateOfBirth}
                errorMessage={dobError}
                placeholder="DD-MM-YYYY"
                format="##-##-####"
                mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                id="dateOfBirth"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="identityType">
            <StyledFormLabel column sm="3">
              Identity Type
            </StyledFormLabel>
            <Col sm="4">
              <StyledFormCheck
                type="radio"
                label="Driver's Licence"
                checked={
                  formik.values.identityType === IdentityTypes.DriversLicense
                }
                onChange={() => identityTypeChange(IdentityTypes.DriversLicense)}
              />
              
            </Col>
            <Col sm="5">
            <StyledFormCheck
                type="radio"
                label="Passport"
                checked={formik.values.identityType === IdentityTypes.Passport}
                onChange={() => identityTypeChange(IdentityTypes.Passport)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="idDocument">
            <StyledFormLabel column sm="3">
                {formik.values.identityType === IdentityTypes.DriversLicense
                  ? "Licence Photo"
                  : "Passport Photo"}
            </StyledFormLabel>
            <Col sm="9">
              <Input
                type="file"
                inputRef={inputRef}
                onChange={idDocumentHandleChange}
                isTouched={formik.touched.idDocument}
                errorMessage={formik.errors.idDocument}
              />
            </Col>
          </Form.Group>
          {formik.values.identityType === IdentityTypes.DriversLicense && (
            <Form.Group as={Row} className="mb-4" controlId="licenseExpiry">
              <StyledFormLabel column sm="3">
                Licence Expiry
              </StyledFormLabel>
              <Col sm="9">
                <Input
                  type="text"
                  value={formik.values.licenseExpiry}
                  onChange={formik.handleChange}
                  isTouched={formik.touched.licenseExpiry}
                  errorMessage={licenseExpiryError}
                  placeholder="DD-MM-YYYY"
                  format="##-##-####"
                  mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                  id="licenseExpiry"
                />
              </Col>
            </Form.Group>
          )}
          {formik.values.identityType === IdentityTypes.DriversLicense && (
            <Form.Group as={Row} className="mb-4" controlId="licenseState">
              <StyledFormLabel column sm="3">
                Licence State
              </StyledFormLabel>
              <Col sm="9">
                <Select
                  value={formik.values.licenseState}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  isTouched={formik.touched.licenseState}
                  errorMessage={formik.errors.licenseState} 
                >
                  <option value="" disabled>Select a state</option>
                  <option value="ACT">ACT</option>
                  <option value="NSW">NSW</option>
                  <option value="NT">NT</option>
                  <option value="QLD">QLD</option>
                  <option value="SA">SA</option>
                  <option value="TAS">TAS</option>
                  <option value="VIC">VIC</option>
                  <option value="WA">WA</option>
                </Select>
              </Col>
            </Form.Group>
          )}
          {((formik.values.identityType === IdentityTypes.DriversLicense &&
          statesWithLicenseNumber?.some(
            (s) => s.name === formik.values.licenseState
          )) || formik.values.identityType === IdentityTypes.Passport) && 
          <>
          <Form.Group as={Row} className="mb-4" controlId="identityNumber">
            <StyledFormLabel column sm="3">
                {formik.values.identityType === IdentityTypes.DriversLicense
                  ? "Licence Number"
                  : "Passport Number"}
            </StyledFormLabel>
            <Col sm="9">
              <Input
                type="text"
                value={formik.values.identityNumber}
                onChange={formik.handleChange}
                isTouched={formik.touched.identityNumber}
                errorMessage={idNumberError}
                placeholder={formik.values.identityType === IdentityTypes.DriversLicense
                  ? "Type your licence number"
                  : "Type your passport number"}
              />
            </Col>
          </Form.Group>
            <Row className="mb-2">
                    <Col sm="3"></Col>
                    <Col sm="9">
                      <CardNumberLocations>
                      {statesWithLicenseNumber
                      .filter((c) => c.name === formik.values.licenseState)[0]
                      ?.images.map((m, i) => (
                        <img key={"license_number_" + i} src={m} alt={"location of Licence number"} />
                      ))}
                      </CardNumberLocations>
                    </Col>
                </Row>
            </>
          }
          {formik.values.identityType === IdentityTypes.DriversLicense &&
            statesWithCardNumber?.some(
              (s) => s.name === formik.values.licenseState
            ) && (
              <>
                <Form.Group as={Row} className="mb-4" controlId="rtaNumber">
                  <StyledFormLabel column sm="3">
                    Card Number
                  </StyledFormLabel>
                  <Col sm="9">
                    <Input
                      type="text"
                      value={formik.values.rtaNumber}
                      onChange={formik.handleChange}
                      isTouched={formik.touched.rtaNumber}
                      errorMessage={formik.errors.rtaNumber}
                      id="rtaNumber"
                      placeholder="Type your card number"
                    />
                    
                  </Col>                  
                </Form.Group>
                <Row className="mb-2">
                    <Col sm="3"></Col>
                    <Col sm="9">
                      <CardNumberLocations>
                        {statesWithCardNumber
                          .filter((c) => c.name === formik.values.licenseState)[0]
                          ?.images.map((m, i) => (
                            <img key={"license_cardnum_" + i} src={m} alt={"location of card number"} />
                          ))}
                      </CardNumberLocations>
                    </Col>
                </Row>
              </>
            )}
          {!authenticatedUser?.hasExceededMaxVerificationAttempts && (
            <FormErrorCenter message={saveIdentityDataError} />
          )}
          {authenticatedUser?.hasExceededMaxVerificationAttempts &&
            !savingIdentityData && (
              <VerificationExceededBadge
                className="mb-4"
                client={client}
                showButton={true}
                header="Max attempts reached"
              />
            )}
          <PrimaryButton
            color="white"
            background={client?.settings?.theme?.primaryColorHex}
            type="submit"
            disabled={savingIdentityData}
            loading={savingIdentityData}
          >
            Submit
          </PrimaryButton>
        </Container>
      </Form>
    </Modal>
  );
};

export default memo(IdentityDocumentModal);
