import { memo } from "react";
import Modal from "../modal/Modal";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
import { passwordResetModalVisibleSelector } from "../../selectors/uiSelectors";
import { RootState } from "store";
import { requestPasswordResetSuccessSelector } from "selectors/userSelectors";
import PasswordResetStep1Form from "components/passwordResetStep1Form/PasswordResetStep1Form";
import PasswordResetStep2Form from "components/passwordResetStep2Form/PasswordResetStep2Form";

const PasswordResetModal = () => {
  const dispatch = useAppDispatch();

  const passwordResetModalVisible = useAppSelector((state: RootState) =>
    passwordResetModalVisibleSelector(state)
  );

  const requestPasswordResetSuccess = useAppSelector((state: RootState) =>
    requestPasswordResetSuccessSelector(state)
  );

  const onCancel = () => {
    dispatch(uiSlice.actions.showPasswordResetModal(false));
  };

  return (
    <Modal
      title="Password Reset"
      onCancel={onCancel}
      visible={passwordResetModalVisible}
    >
      {!requestPasswordResetSuccess && <PasswordResetStep1Form />}
      {requestPasswordResetSuccess && <PasswordResetStep2Form />}
    </Modal>
  );
};

export default memo(PasswordResetModal);
