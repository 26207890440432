import { memo, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { RootState } from "store";
import { signUpModalVisibleSelector } from "../../selectors/uiSelectors";
import { clientSelector } from "../../selectors/clientSelectors";
import { useAppDispatch, useAppSelector } from "hooks";
import { uiSlice } from "reducers/uiSlice";
import { userSlice } from "reducers/userSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserRegistration } from "types/userRegistration";
import Modal from "../modal/Modal";
import CheckBox from "components/checkBox/CheckBox";
import FormError from "components/formError/FormError";
import {
  registeringUserSelector,
  registerUserErrorMessageSelector,
  registerUserErrorsSelector,
  registerUserSuccessSelector,
} from "selectors/userSelectors";
import Input from "components/input/Input";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import styled from "styled-components";
import ModalNotice from "components/modalNotice/ModalNotice";
import { RegisterUserErrors } from "types/registerUserErrors";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const Notice = styled.div`
  text-align: center;
  color: #707070;
  font-size: 1.6rem;
  margin-top: -15px;
  margin-bottom: 20px;
  font-weight: 500;

  > i {
    font-style: normal;
    font-size: 1.2rem;
    display: block;
    font-weight: 400;
  }
  @media (max-width: 520px) {
    font-size: 1rem;
    > i {
      font-size: 0.7rem;
    }
  }
`;

const CheckboxText = styled.span`
  font-size: 0.9rem;
  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
`;

const SignUpModal = () => {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const registerUserErrorMessage = useAppSelector((state: RootState) =>
    registerUserErrorMessageSelector(state)
  );

  const registerUserErrors = useAppSelector((state: RootState) =>
    registerUserErrorsSelector(state)
  );

  const signUpModalVisible = useAppSelector((state: RootState) =>
    signUpModalVisibleSelector(state)
  );

  const registeringUser = useAppSelector((state: RootState) =>
    registeringUserSelector(state)
  );

  const registerUserSuccess = useAppSelector((state: RootState) =>
    registerUserSuccessSelector(state)
  );

  const schema = Yup.object({
    firstName: Yup.string().required("First Name is a required field."),
    lastName: Yup.string().required("Last Name is a required field."),
    email: Yup.string().email("Email must be in the correct format.").required("Email is a required field."),
    password: Yup.string().required("Password is a required field."),
    mobile: Yup.string().required("Mobile Number is a required field."),
    agreed: Yup.string()
      .required()
      .equals(
        ["true"],
        "You must agree to the Terms and Conditions & Privacy Policy."
      ),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      agreed: "false",
    },
    onSubmit: (values: any) => {
      const userRegistration: UserRegistration = {
        ...values,
        mobile: values.mobile.replace("+", "").replaceAll("-", "").replaceAll(" ", "")
      };
      dispatch(userSlice.actions.registerUser(userRegistration));
    },
  });

  useEffect(() => {
    if (registerUserSuccess && signUpModalVisible) {
      formik.resetForm();
      dispatch(uiSlice.actions.showSignUpModal(false));
    }
  }, [dispatch, formik, registerUserSuccess, signUpModalVisible]);

  const onCancel = () => {
    formik.resetForm();
    dispatch(uiSlice.actions.showSignUpModal(false));
  };
  
  const emailError = formik.errors.email ?? (registerUserErrors as RegisterUserErrors).Email;
  const mobileError = formik.errors.mobile ?? (registerUserErrors as RegisterUserErrors).Mobile;
  const passwordError = formik.errors.password ?? (registerUserErrors as RegisterUserErrors).Password;

  return (
    <Modal
      visible={signUpModalVisible}
      title="Create Account"
      onCancel={onCancel}
    >
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Container>
          <Notice>
            Create an account on {client?.name}
            <i>Sign up for an account to start investing in deals.</i>
          </Notice>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4" controlId="firstName">
                <Input
                  type="text"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  isTouched={formik.touched.firstName}
                  errorMessage={formik.errors.firstName}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4" controlId="lastName">
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  isTouched={formik.touched.lastName}
                  errorMessage={formik.errors.lastName}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-4" controlId="email">
            <Input
              type="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isTouched={formik.touched.email}
              errorMessage={emailError}
              tooltip="Your email address is used to login and perform a forgotten password request"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="mobile">
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control shadow-none inputMobilePhone"
              fieldId="mobile"
              fieldName="mobile"
              onPhoneNumberChange={(
                isValid,
                value,
                selectedCountryData,
                fullNumber
              ) => {
                formik.handleChange("mobile")(fullNumber);
                console.log(isValid, value, selectedCountryData);
              }}
              defaultCountry={client?.settings?.theme?.countryCode ?? "au"}
              preferredCountries={['au', 'ca']}
            />
            {formik.errors.mobile ? (
              <div className="sc-dLMFU BVFTb">{mobileError}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Input
              type="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              isTouched={formik.touched.password}
              errorMessage={passwordError}
            />
            <ModalNotice className="mt-2">
              Use 6 or more characters containing at least one upper case, one
              number and one non alphanumeric character.
            </ModalNotice>
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="agreed">
            <CheckBox
              defaultChecked={formik.values.agreed === "true"}
              value={formik.values.agreed}
              onChange={formik.handleChange}
              isTouched={formik.touched.agreed}
              errorMessage={formik.errors.agreed}
            >
              <CheckboxText>
                I agree to the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={client?.termsAndConditionsUrl}
                >
                  Terms and Conditions
                </a>{" "}
                &{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={client?.privacyPolicyUrl}
                >
                  Privacy Policy
                </a>
              </CheckboxText>
            </CheckBox>
          </Form.Group>
          <FormError message={registerUserErrorMessage} />
          <PrimaryButton
            color="white"
            background={client?.settings?.theme?.primaryColorHex}
            type="submit"
            disabled={registeringUser}
            loading={registeringUser}
          >
            Create Account Now
          </PrimaryButton>
        </Container>
      </Form>
    </Modal>
  );
};

export default memo(SignUpModal);
