import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "types/location";
import { UserRegistration } from "types/userRegistration";
import { SignIn } from "types/signIn";
import {
  showPasswordResetModal,
  showSignInModal,
  showSignUpModal,
  showIdentityDocumentModal,
  showBankAccountModal,
  showVerifyMobileSuccessModal,
  showBidSubmittedModal,
} from "reducers/uiSlice";
import { RequestPasswordReset } from "types/requestPasswordReset";
import { AuthenticatedUser } from "types/authenticatedUser";
import { ChangePassword } from "types/changePassword";
import { SaveIdentityData } from "types/saveIdentityData";
import { VerifyMobile } from "types/verifyMobile";
import { GetSophisticatedApplicationLinkResponse } from "types/getSophisticatedApplicationLinkResponse";
import { SaveBankAccountInformation } from "types/saveBankAccountInformation";
import { RegisterUserErrors } from "types/registerUserErrors";
import { SaveIdentityErrors } from "types/saveIdentityErrors";
import { GetOnlineAccountRegistrationStatusResponse } from "types/getOnlineAccountRegistrationStatusResponse";

interface UserState {
  token: string;
  registeringUser: boolean;
  registerUserSuccess: boolean;
  registerUserErrorMessage: string | undefined;
  registerUserErrors: RegisterUserErrors | undefined;
  savingSophisticatedApplication: boolean;
  saveSophisticatedApplicationSuccess: boolean;
  validatingAddress: boolean;
  validatedAddresses: Location[];
  signingIn: boolean;
  signInSuccess: boolean;
  signInError: string | undefined;
  requestingPasswordReset: boolean;
  requestPasswordResetSuccess: boolean;
  requestPasswordResetError: string | undefined;
  changingPassword: boolean;
  changePasswordSuccess: boolean;
  changePasswordError: string | undefined;
  gettingAuthenticatedUser: boolean;
  authenticatedUser: AuthenticatedUser | undefined;
  savingIdentityData: boolean;
  saveIdentityDataSuccess: boolean;
  saveIdentityDataError: string | undefined;
  saveIdentityErrors: SaveIdentityErrors | undefined;
  verifyingMobile: boolean;
  verifyMobileSuccess: boolean;
  verifyMobileError: string | undefined;
  resendingMobileVerification: boolean;
  resendMobileVerificationSuccess: boolean;
  resendMobileVerificationError: string | undefined;
  sophisticatedApplicationLinkLoading: boolean;
  sophisticatedApplicationLink: string | undefined;
  onlineAccountRegistrationLoading: boolean;
  onlineAccountRegistrationStatus: number | 0;
  onlineAccountRegistrationUrl: string | undefined;
  savingBankAccountInformation: boolean;
  saveBankAccountInformationSuccess: boolean;
  saveBankAccountInformationError: string | undefined;
}

const initialState: UserState = {
  token: localStorage.getItem("jwt") ?? "",
  registeringUser: false,
  registerUserSuccess: false,
  registerUserErrorMessage: undefined,
  registerUserErrors: { },
  savingSophisticatedApplication: false,
  saveSophisticatedApplicationSuccess: false,
  validatingAddress: false,
  validatedAddresses: [],
  signingIn: false,
  signInSuccess: false,
  signInError: undefined,
  requestingPasswordReset: false,
  requestPasswordResetSuccess: false,
  requestPasswordResetError: undefined,
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordError: undefined,
  gettingAuthenticatedUser: false,
  authenticatedUser: undefined,
  savingIdentityData: false,
  saveIdentityDataSuccess: false,
  saveIdentityDataError: undefined,
  saveIdentityErrors: {},
  verifyingMobile: false,
  verifyMobileSuccess: false,
  verifyMobileError: undefined,
  resendingMobileVerification: false,
  resendMobileVerificationSuccess: false,
  resendMobileVerificationError: undefined,
  sophisticatedApplicationLinkLoading: false,
  sophisticatedApplicationLink: undefined,
  onlineAccountRegistrationLoading: false,
  onlineAccountRegistrationStatus: 0,
  onlineAccountRegistrationUrl: undefined,
  savingBankAccountInformation: false,
  saveBankAccountInformationSuccess: false,
  saveBankAccountInformationError: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearSophisticatedApplicationLink: (state) => {
      state.sophisticatedApplicationLink = undefined;
    },
    loadSophisticatedApplicationLink: (state) => {
      state.sophisticatedApplicationLinkLoading = true;
      state.sophisticatedApplicationLink = undefined;      
    },
    loadSophisticatedApplicationLinkSuccess: (
      state,
      action: PayloadAction<GetSophisticatedApplicationLinkResponse>
    ) => {
      state.sophisticatedApplicationLinkLoading = false;
      state.sophisticatedApplicationLink = action.payload.url;
    },
    loadSophisticatedApplicationLinkFailed: (state) => {
      state.sophisticatedApplicationLinkLoading = false;
      state.sophisticatedApplicationLink = undefined;
    },
    clearOnlineAccountRegistrationStatus: (state) => {
      state.onlineAccountRegistrationStatus = -1;
      state.onlineAccountRegistrationUrl = undefined;
    },
    loadOnlineAccountRegistrationStatus: (state) => {
      state.onlineAccountRegistrationLoading = true;
      state.onlineAccountRegistrationStatus = -1;
      state.onlineAccountRegistrationUrl = undefined;
    },
    loadOnlineAccountRegistrationStatusSuccess: (state, action: PayloadAction<GetOnlineAccountRegistrationStatusResponse>) => {
      state.onlineAccountRegistrationLoading = false;
      state.onlineAccountRegistrationStatus = action.payload.status;
      state.onlineAccountRegistrationUrl = action.payload.url;
    },
    loadOnlineAccountRegistrationStatusFailed: (state) => {
      state.onlineAccountRegistrationLoading = false;
      state.onlineAccountRegistrationStatus = -1;
      state.onlineAccountRegistrationUrl = undefined;
    },
    registerUser: (state, _action: PayloadAction<UserRegistration>) => {
      state.registeringUser = true;
      state.registerUserSuccess = false;
      state.registerUserErrorMessage = undefined;
      state.registerUserErrors = { };
    },
    registerUserSuccess: (state, _action: PayloadAction<number>) => {
      state.registeringUser = false;
      state.registerUserSuccess = true;
    },
    registerUserFailed: (state, action: PayloadAction<string>) => {
      state.registeringUser = false;
      state.registerUserSuccess = false;
      state.registerUserErrorMessage = action.payload;
    },    
    registerUserErrors: (state, action: PayloadAction<RegisterUserErrors>) => {
      state.registerUserErrors = action.payload;
    },   
    validateAddress: (state, _action: PayloadAction<string>) => {
      state.validatingAddress = true;
      state.validatedAddresses = [];
    },
    validateAddressSuccess: (state, action: PayloadAction<Location[]>) => {
      state.validatingAddress = false;
      state.validatedAddresses = action.payload;
    },
    validateAddressFailed: (state) => {
      state.validatingAddress = false;
      state.validatedAddresses = [];
    },
    getAuthenticatedUser: (state) => {
      state.gettingAuthenticatedUser = true;
    },
    getAuthenticatedUserSuccess: (
      state,
      action: PayloadAction<AuthenticatedUser>
    ) => {
      state.authenticatedUser = action.payload;
      state.gettingAuthenticatedUser = false;
      if (!action.payload.hasVerifiedMobile) {
        state.resendMobileVerificationError = undefined;
        state.resendMobileVerificationSuccess = false;
      }
    },
    getAuthenticatedUserFailed: (state) => {
      state.gettingAuthenticatedUser = false;
      state.authenticatedUser = undefined;
    },
    signIn: (state, _action: PayloadAction<SignIn>) => {
      state.signingIn = true;
      state.signInSuccess = false;
      state.signInError = undefined;
    },
    signInSuccess: (state, action: PayloadAction<string>) => {
      state.signingIn = false;
      state.signInSuccess = true;
      state.token = action.payload;
      localStorage.setItem("jwt", action.payload);
    },
    signInFailed: (state, action: PayloadAction<string>) => {
      state.signingIn = false;
      state.signInError = `Sign In Failed. ${action.payload}`;
    },
    signOut: (state) => {
      state.token = "";
      localStorage.removeItem("jwt");
      state.authenticatedUser = undefined;
    },
    requestPasswordReset: (
      state,
      _action: PayloadAction<RequestPasswordReset>
    ) => {
      state.requestingPasswordReset = true;
      state.requestPasswordResetSuccess = false;
    },
    requestPasswordResetSuccess: (state) => {
      state.requestingPasswordReset = false;
      state.requestPasswordResetSuccess = true;
    },
    requestPasswordResetFailed: (state, action: PayloadAction<string>) => {
      state.requestingPasswordReset = false;
      state.requestPasswordResetError = `Password Reset Failed. ${action.payload}`;
    },
    changePassword: (state, _action: PayloadAction<ChangePassword>) => {
      state.changingPassword = true;
      state.changePasswordSuccess = false;
      state.changePasswordError = undefined;
    },
    changePasswordSuccess: (state) => {
      state.changingPassword = false;
      state.changePasswordSuccess = true;
    },
    changePasswordFailed: (state, action: PayloadAction<string>) => {
      state.changingPassword = false;
      state.changePasswordError = `Change Password Failed. ${action.payload}`;
    },
    saveIdentityData: (state, _action: PayloadAction<SaveIdentityData>) => {
      state.savingIdentityData = true;
      state.saveIdentityDataSuccess = false;
      state.saveIdentityDataError = undefined;
      state.saveIdentityErrors = {};
    },
    saveIdentityDataSuccess: (state) => {
      state.savingIdentityData = false;
      state.saveIdentityDataSuccess = true;
    },
    saveIdentityDataFailed: (state, action: PayloadAction<string>) => {
      state.savingIdentityData = false;
      state.saveIdentityDataError = `${action.payload}`;
    },
    saveIdentityErrors: (state, action: PayloadAction<SaveIdentityErrors>) => {
      state.saveIdentityErrors = action.payload;
    },
    verifyMobile: (state, _action: PayloadAction<VerifyMobile>) => {
      state.verifyingMobile = true;
      state.verifyMobileSuccess = false;
      state.verifyMobileError = undefined;
      state.resendMobileVerificationSuccess = false;
      state.resendMobileVerificationError = undefined;
    },
    verifyMobileSuccess: (state) => {
      state.verifyingMobile = false;
      state.verifyMobileSuccess = true;
    },
    verifyMobileFailed: (state, action: PayloadAction<string>) => {
      state.verifyingMobile = false;
      state.verifyMobileError = `Verification Failed. ${action.payload}`;
    },
    resendMobileVerification: (state, _action: PayloadAction<boolean>) => {
      state.resendingMobileVerification = true;
      state.resendMobileVerificationSuccess = false;
      state.resendMobileVerificationError = undefined;
      state.verifyMobileError = undefined;
    },
    resendMobileVerificationSuccess: (state) => {
      state.resendingMobileVerification = false;
      state.resendMobileVerificationSuccess = true;
    },
    resendMobileVerificationFailed: (state, action: PayloadAction<string>) => {
      state.resendingMobileVerification = false;
        state.resendMobileVerificationError = `Error resending verification code. ${action.payload}`;
    },
    saveSophisticatedApplication: (state) => {
      state.savingSophisticatedApplication = true;
      state.saveSophisticatedApplicationSuccess = false;
    },
    saveSophisticatedApplicationSuccess: (state) => {
      state.savingSophisticatedApplication = false;
      state.saveSophisticatedApplicationSuccess = true;
    },
    saveSophisticatedApplicationFailed: (state) => {
      state.savingSophisticatedApplication = false;
    },
    saveBankAccountInformation: (
      state,
      _action: PayloadAction<SaveBankAccountInformation>
    ) => {
      state.savingBankAccountInformation = true;
      state.saveBankAccountInformationSuccess = false;
      state.saveBankAccountInformationError = undefined;
    },
    saveBankAccountInformationSuccess: (state) => {
      state.savingBankAccountInformation = false;
      state.saveBankAccountInformationSuccess = true;
    },
    saveBankAccountInformationFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.savingBankAccountInformation = false;
      state.saveBankAccountInformationError = action.payload;
    },
  },
  extraReducers: {
    [showSignUpModal.type]: (state) => {
      state.validatingAddress = false;
      state.validatedAddresses = [];
      state.registeringUser = false;
      state.registerUserSuccess = false;
      state.registerUserErrorMessage = undefined;
      state.registerUserErrors = { };
    },
    [showSignInModal.type]: (state) => {
      state.signInSuccess = false;
      state.signingIn = false;
      state.signInError = undefined;
    },
    [showPasswordResetModal.type]: (state) => {
      state.changingPassword = false;
      state.changePasswordSuccess = false;
      state.changePasswordError = undefined;
      state.requestingPasswordReset = false;
      state.requestPasswordResetSuccess = false;
      state.requestPasswordResetError = undefined;
    },
    [showIdentityDocumentModal.type]: (state) => {
      state.saveIdentityDataSuccess = false;
      state.saveIdentityDataError = undefined;
      state.saveIdentityErrors = { };
    },
    [showBankAccountModal.type]: (state) => {
      state.saveBankAccountInformationSuccess = false;
      state.saveBankAccountInformationError = undefined;
    },
    [showBidSubmittedModal.type]: (state) => {
      state.saveBankAccountInformationSuccess = false;
      state.saveBankAccountInformationError = undefined;
    },
    [showVerifyMobileSuccessModal.type]: (state) => {
      state.verifyMobileSuccess = false;
      state.resendMobileVerificationSuccess = false;
      state.verifyMobileError = undefined;
      state.resendMobileVerificationError = undefined;
    },
  },
});

export const { registerUserSuccess } = userSlice.actions;
export const { registerUserFailed } = userSlice.actions;
export const { registerUserErrors } = userSlice.actions;

export const { signIn } = userSlice.actions;
export const { signInSuccess } = userSlice.actions;
export const { signInFailed } = userSlice.actions;

export const { validateAddressSuccess } = userSlice.actions;
export const { validateAddressFailed } = userSlice.actions;

export const { requestPasswordResetSuccess } = userSlice.actions;
export const { requestPasswordResetFailed } = userSlice.actions;

export const { changePasswordSuccess } = userSlice.actions;
export const { changePasswordFailed } = userSlice.actions;

export const { getAuthenticatedUser } = userSlice.actions;
export const { getAuthenticatedUserSuccess } = userSlice.actions;
export const { getAuthenticatedUserFailed } = userSlice.actions;

export const { saveIdentityDataSuccess } = userSlice.actions;
export const { saveIdentityDataFailed } = userSlice.actions;
export const { saveIdentityErrors } = userSlice.actions;

export const { verifyMobileSuccess } = userSlice.actions;
export const { verifyMobileFailed } = userSlice.actions;

export const { saveBankAccountInformationSuccess } = userSlice.actions;
export const { saveBankAccountInformationFailed } = userSlice.actions;

export const { resendMobileVerification } = userSlice.actions;
export const { resendMobileVerificationSuccess } = userSlice.actions;
export const { resendMobileVerificationFailed } = userSlice.actions;

export const { saveSophisticatedApplication } = userSlice.actions;
export const { saveSophisticatedApplicationSuccess } = userSlice.actions;
export const { saveSophisticatedApplicationFailed } = userSlice.actions;

export const { loadSophisticatedApplicationLink } = userSlice.actions;
export const { loadSophisticatedApplicationLinkSuccess } = userSlice.actions;
export const { loadSophisticatedApplicationLinkFailed } = userSlice.actions;

export const { loadOnlineAccountRegistrationStatus } = userSlice.actions;
export const { loadOnlineAccountRegistrationStatusSuccess } = userSlice.actions;
export const { loadOnlineAccountRegistrationStatusFailed } = userSlice.actions;

export default userSlice.reducer;
