import { memo } from "react";
import { Button, Spinner } from "react-bootstrap";
import styled from "styled-components";

interface LinkButtonProps {
  children: any;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
  fontSize?: string;
  fontWeight?: string;
}

const StyledButton = styled(Button)<{ $fontSize: string; $fontWeight: string }>`
  display: block;
  width: 100%;
  min-height: 50px;
  box-shadow: none !important;
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
`;

const LinkButton = (props: LinkButtonProps) => {
  const {
    disabled,
    onClick,
    children,
    loading,
    className,
    fontSize,
    fontWeight,
  } = props;

  return (
    <StyledButton
      disabled={disabled}
      variant="link"
      onClick={onClick}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      className={className}
    >
      {loading === true && <Spinner animation="border" />}
      {loading !== true && children}
    </StyledButton>
  );
};

LinkButton.defaultProps = {
  disabled: false,
  fontSize: "1rem",
  fontWeight: "500",
};

export default memo(LinkButton);
