import { memo, useLayoutEffect, useState } from "react";
import { Offer } from "types/offer";
import Modal from "components/modal/Modal";
import LabeledContent from "components/labeledContent/LabeledContent";
import Company from "components/company/Company";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { clientSelector } from "selectors/clientSelectors";
import { ToSydneyTime } from "utilities/time";

import DateTile from "components/dateTile/DateTile";
import { authenticatedUserSelector, displayNameSelector, onlineAccountRegistrationLoadingSelector, onlineAccountRegistrationStatusSelector } from "selectors/userSelectors";
import { bidLinkLoadingSelector } from "selectors/offerSelectors";
import { uiSlice } from "reducers/uiSlice";
import Currency from "components/currency/Currency";
import OfferDocuments from "components/offerDocuments/OfferDocuments";
import * as moment from "moment-timezone";
import { FONT_SIZES } from "../../constants";

interface OfferViewModalProps {
  offer: Offer;
  onClose: () => void;
  onBidNow: () => void;
}

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 140px;
  background: #f8f8f8;
  padding: 15px 0 18px 18px;
  border-radius: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 120px;
  }
`;

const Right = styled.div`
  flex: 1;
  padding: 15px 18px 18px;
`;

const BrokerWrapper = styled.div`
  height: 145px;
`;

const BrokerLogo = styled.img`
  width: 100px;
  @media (max-width: 450px) {
    width: 80px;
  }
`;

const LastBidNotice = styled.div`
  font-size: 0.85rem;
  font-weight: 400;
  color: #0c6800;
  margin-top: 5px;

  > b {
    display: block;
  }
`;

const OfferViewModal = (props: OfferViewModalProps) => {
  const { offer, onBidNow } = props;

  const dispatch = useAppDispatch();

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );
  
  const displayName = useAppSelector((state: RootState) =>
    displayNameSelector(state)
  );

  const bidLinkLoading = useAppSelector((state: RootState) =>
    bidLinkLoadingSelector(state)
  );

  const onlineAccountRegistrationStatus = useAppSelector((state: RootState) =>
    onlineAccountRegistrationStatusSelector(state)
  );
  
  const onlineAccountRegistrationLoading = useAppSelector((state: RootState) =>
    onlineAccountRegistrationLoadingSelector(state)
  );

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const createAccount = () => {
    dispatch(uiSlice.actions.showSignUpModal(true));
    props.onClose();
  };

  function handleSignInToView() {
    dispatch(uiSlice.actions.showSignInModal(true));
    props.onClose();
  }

  const closesOnSydneyConverted = ToSydneyTime(offer.closesOn);
  const createdOnSydneyConverted = ToSydneyTime(offer.availableOn);
  const lastBidOnLocal = offer.lastBidOnUtc
    ? moment.utc(offer.lastBidOnUtc).local()
    : null;

  const userTimezone = moment.tz.zone(moment.tz.guess());
  const showCurrencyCode = client?.settings?.theme?.showCurrency ?? false;  
  let bidNowText = client?.settings?.theme?.liveDealDialogBidButtonText || 'Bid Now';

  if ((client?.settings?.workflow?.enableOnlineAccountRegistrationForm && onlineAccountRegistrationStatus === 2) ||
      authenticatedUser?.verificationStatus === 'Verified')
    bidNowText = 'Bid Now';

  return (
    <Modal
      title="Live Deal Info"
      onCancel={props.onClose}
      visible={true}
      bodyPadding="0"
    >
      <Wrapper>
        <Left>
          {client?.settings?.offers?.hideBrokerInformation !== true && (
            <BrokerWrapper>
              <LabeledContent label="Broker">
                {offer.brokerLogoUrl && (
                  <BrokerLogo src={offer.brokerLogoUrl} />
                )}
                {!offer.brokerLogoUrl && offer.broker}
              </LabeledContent>
            </BrokerWrapper>
          )}
          <LabeledContent label="Deal Related Documents">
            <OfferDocuments
              primaryColorHex={client?.settings?.theme?.primaryColorHex}
              offer={offer}
              onSignInToView={handleSignInToView}
            />
          </LabeledContent>
        </Left>
        <Right>
          <LabeledContent label="Company">
            <Company
              name={offer.company}
              logoUrl={offer.companyLogoUrl}
              logoSize={width > 450 ? "100px" : "60px"}
              tickerSymbol={client?.settings?.theme?.tickerSymbol || 'ASX'}
              asxCode={offer.asxCode}
              nameFontSize={
                width > 500 ? FONT_SIZES.ExtraLarge : FONT_SIZES.Large
              }
            />
            <Row className="mt-3">
              <Col>
                <LabeledContent label="Raise Amount">
                  <Currency
                    amount={offer.amount}
                    fontSize={
                      width > 500 ? FONT_SIZES.ExtraLarge : FONT_SIZES.Large
                    }
                    fontWeight="700"
                    showCode={showCurrencyCode} code={offer.currencyCode}
                  />
                </LabeledContent>
              </Col>
              <Col>
                <LabeledContent label="Price">
                  <Currency
                    amount={offer.sharePrice}
                    fontSize={
                      width > 500 ? FONT_SIZES.ExtraLarge : FONT_SIZES.Large
                    }
                    fontWeight="700"
                    showCode={showCurrencyCode} code={offer.currencyCode}
                  />
                </LabeledContent>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <Col>
                <LabeledContent label="Deal Created">
                  <DateTile
                    fontSize={
                      width > 500 ? FONT_SIZES.Large : FONT_SIZES.Medium
                    }
                    date={createdOnSydneyConverted}
                  />
                </LabeledContent>
              </Col>
              <Col>
                <LabeledContent label="Deal Close">
                  <DateTile
                    fontSize={
                      width > 500 ? FONT_SIZES.Large : FONT_SIZES.Medium
                    }
                    date={closesOnSydneyConverted}
                  />
                </LabeledContent>
              </Col>
            </Row>
            {displayName && (
              <>
                <PrimaryButton
                  color="white"
                  background={client?.settings?.theme?.primaryColorHex}
                  onClick={onBidNow}
                  disabled={bidLinkLoading || lastBidOnLocal !== null || onlineAccountRegistrationLoading ||
                  (client?.settings?.workflow?.enableOnlineAccountRegistrationForm && (onlineAccountRegistrationStatus === -1 || onlineAccountRegistrationStatus === 1 ||  onlineAccountRegistrationStatus === 3))}
                  loading={bidLinkLoading}
                >
                  {
                    bidNowText
                  }
                </PrimaryButton>
                {lastBidOnLocal && (
                  <LastBidNotice className="text-center mb-1">
                    <b>Your bid has already been registered.</b>
                    (You bid on this deal{" "}
                    {lastBidOnLocal?.format((process.env["REACT_APP_DATE_FORMAT"] || "DD/MM/yyyy") + " HH:mm")}{" "}
                    {userTimezone?.abbrs[0]})
                  </LastBidNotice>
                )}
              </>
            )}
            {!displayName && (
              <>
                <PrimaryButton
                  color="white"
                  background={client?.settings?.theme?.primaryColorHex}
                  onClick={createAccount}
                >
                  Create Account
                </PrimaryButton>
              </>
            )}
          </LabeledContent>
        </Right>
      </Wrapper>
    </Modal>
  );
};

export default memo(OfferViewModal);
