import { memo } from "react";
import { Modal as BootrapModal } from "react-bootstrap";
import { RootState } from "store";
import { useAppSelector } from "hooks";
import ModalHeader from "components/modalHeader/ModalHeader";
import styled from "styled-components";
import { clientSelector } from "selectors/clientSelectors";

const Wrapper = styled(BootrapModal)<{ $bodyPadding: string }>`
  padding-top: 5px;
  @media (max-width: 575px) {
    padding-top: 35px;
  }
  .modal-dialog {
    margin-bottom: 10px;
    @media (max-width: 575px) {
      margin-bottom: 5px;
    }
  }
  .modal-content {
    border-width: 0 !important;
    border-radius: 20px;
  }

  .modal-body {
    padding: ${(props) => props.$bodyPadding};
  }
`;

interface ModalProps {
  bodyPadding?: string;
  visible: boolean;
  onCancel: () => void;
  children: any;
  title: string;
  large?: boolean;
  className?: string;
}

const Modal = (props: ModalProps) => {
  const { visible, title, onCancel, children, bodyPadding, className } = props;

  const client = useAppSelector((state: RootState) => clientSelector(state));

  return (
    <Wrapper
      show={visible}
      animation={true}
      $bodyPadding={bodyPadding ?? "2em 1.5em"}
      size={props.large ? "lg" : "md"}
    >
      <ModalHeader
        background={client?.settings?.theme?.primaryColorHex}
        title={title}
        onCancel={onCancel}
      />
      <BootrapModal.Body className={className}>{children}</BootrapModal.Body>
    </Wrapper>
  );
};

export default memo(Modal);
