import { ReactElement, memo } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Badge = styled.div<{ $backgroundColor: string }>`
  background: ${(props) => props.$backgroundColor};
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: white;
  align-self: flex-start;
  align-items: center;
  font-weight: 400;

  > button {
    margin-left: 15px;
    width: 90px;
    padding: 5px;
    margin-left: 12px;
    border-radius: 7px;
    font-weight: 500;
  }

  > svg {
    font-size: 1.7rem;
    margin-right: 15px;
    margin-left: 10px;
  }

  @media (max-width: 660px) {
    > svg {
      display: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    flex: 1;

    > b {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

interface VerificationBadgeProps {
  backgroundColor: string;
  icon?: IconProp;
  reactIcon?: ReactElement;
  children?: any;
  text: any;
  header: string;
  className?: string;
}

const VerificationBadge = (props: VerificationBadgeProps) => {
  const { backgroundColor, children, icon, reactIcon, text, header, className } = props;

  return (
    <Badge $backgroundColor={backgroundColor} className={className}>
      {icon && (<FontAwesomeIcon icon={icon} />)}
      {reactIcon && (reactIcon)}
      <div>
        <b>{header}</b>
        {text}
      </div>
      {children}
    </Badge>
  );
};

export default memo(VerificationBadge);
