import { memo, useState, useLayoutEffect, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import OfferViewModal from "../offerViewModal/OfferViewModal";
import IFrameModal from "../iFrameModal/IFrameModal";
import IdentityDocumentModal from "../identityDocumentModal/IdentityDocumentModal";
import GridFooter from "../gridFooter/GridFooter";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import { getColumns } from "./offerGridColumns";

import { Offer } from "types/offer";
import { useAppDispatch, useAppSelector } from "hooks";
import BidSubmittedConfirmationModal from "components/bidSubmittedConfirmationModal/BidSubmittedConfirmationModal";
import {
  bidLinkSelector,
  saveBidActivitySuccessSelector,
} from "selectors/offerSelectors";
import {
  bankAccountModalVisibleSelector,
  identityDocumentModalVisibleSelector,
  identityDocumentSubmittedModalVisibleSelector,
  sophisticatedApplicationSubmittedModalVisibleSelector,
} from "selectors/uiSelectors";
import { RootState } from "store";
import { offerSlice } from "reducers/offerSlice";
import { uiSlice } from "reducers/uiSlice";
import { userSlice } from "reducers/userSlice";
import {
  authenticatedUserSelector,
  onlineAccountRegistrationStatusSelector,
  onlineAccountRegistrationUrlSelector,
  saveBankAccountInformationSuccessSelector,
  saveSophisticatedApplicationSuccessSelector,
  sophisticatedApplicationLinkSelector,
} from "selectors/userSelectors";

import { FONT_SIZES, WINDOW_MESSAGE_TYPES } from "../../constants";
import { GetClosureInformation } from "utilities/time";
import { clientSelector } from "selectors/clientSelectors";

const SkeletonWrapper = styled.div`
  padding: 5px;
`;

const NoOffersWarning = styled.div`
  border: solid 1px silver;
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
`;

const StyledReactDataGrid = styled(ReactDataGrid)<{ $width: number }>`
  overflow: hidden;
  color: #3d3d3d;
  border-width: 0;

  .InovuaReactDataGrid__header {
    height: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .InovuaReactDataGrid__column-header__content {
    padding: 0;
    font-size: ${(props: any) =>
      props.$width > 660 ? FONT_SIZES.Medium : FONT_SIZES.Small};
  }

  .InovuaReactDataGrid__column-header--show-border-left {
    border-left-color: white;
  }

  .InovuaReactDataGrid__cell--align-center
    > .InovuaReactDataGrid__cell__content {
    justify-content: center;
  }

  .InovuaReactDataGrid__cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-weight: 700;
  }

  .InovuaReactDataGrid__cell.pad-left,
  .InovuaReactDataGrid__column-header.pad-left {
    padding-left: ${(props: any) =>
      props.width > 660 ? "20px" : "10px"} !important;
  }

  .InovuaReactDataGrid__cell.pad-right,
  .InovuaReactDataGrid__column-header.pad-right {
    padding-right: ${(props: any) =>
      props.width > 660 ? "20px" : "10px"} !important;
  }

  .InovuaReactDataGrid__cell--last {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .InovuaReactDataGrid__cell__content {
    height: 100%;
    align-items: center;
    display: flex;
  }

  .InovuaReactDataGrid__cell--align-end .InovuaReactDataGrid__cell__content {
    justify-content: end;
  }

  .InovuaReactDataGrid__row.disabled {
    background: #f8f8f8;
  }
`;

const GridWrapper = styled.div<{ $width: number }>`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: solid 1px
    ${(props: any) => (props.$width > 450 ? "#e4e3e2" : "transparent")} !important;
`;

interface OffersGridProps {
  offers: Offer[] | null;
  offersLoading?: boolean;
}

const OffersGrid = (props: OffersGridProps) => {
  const { offers, offersLoading } = props;

  const dispatch = useAppDispatch();

  const [width, setWidth] = useState(0);

  const [confirmedBankAccount, setConfirmedBankAccount] = useState(true);

  const bidLink = useAppSelector((state: RootState) => bidLinkSelector(state));

  const saveBidActivitySuccess = useAppSelector((state: RootState) =>
    saveBidActivitySuccessSelector(state)
  );

  const authenticatedUser = useAppSelector((state: RootState) =>
    authenticatedUserSelector(state)
  );

  const client = useAppSelector((state: RootState) => clientSelector(state));

  const saveBankAccountInformationSuccess = useAppSelector((state: RootState) =>
    saveBankAccountInformationSuccessSelector(state)
  );

  const identityDocumentModalVisible = useAppSelector((state: RootState) =>
    identityDocumentModalVisibleSelector(state)
  );

  const saveSophisticatedApplicationSuccess = useAppSelector(
    (state: RootState) => saveSophisticatedApplicationSuccessSelector(state)
  );

  const identityDocumentSubmittedModalVisible = useAppSelector(
    (state: RootState) => identityDocumentSubmittedModalVisibleSelector(state)
  );

  const bankAccountModalVisible = useAppSelector((state: RootState) =>
    bankAccountModalVisibleSelector(state)
  );

  const sophisticatedApplicationSubmittedModalVisible = useAppSelector(
    (state: RootState) =>
      sophisticatedApplicationSubmittedModalVisibleSelector(state)
  );

  const sophisticatedApplicationLink = useAppSelector((state: RootState) =>
    sophisticatedApplicationLinkSelector(state)
  );

  const onlineAccountRegistrationStatus = useAppSelector((state: RootState) =>
    onlineAccountRegistrationStatusSelector(state)
  );
  const onlineAccountRegistrationUrl = useAppSelector((state: RootState) =>
    onlineAccountRegistrationUrlSelector(state)
  );
  
  useEffect(() => {
    if (
      !offersLoading &&
      offers &&
      window.location.hash.startsWith("#offerId")
    ) {
      const offerId = Number(window.location.hash.substring(9));
      const offer = offers.filter((o) => o.id === offerId)[0];
      if (offer) {
        const closureInformation = GetClosureInformation(offer.closesOn);
        if (!closureInformation.expired) setSelectedOffer(offer);
      }
      window.location.hash = "";
    }
  }, [offersLoading, offers]);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (saveSophisticatedApplicationSuccess) {
      dispatch(userSlice.actions.clearSophisticatedApplicationLink());
      dispatch(userSlice.actions.getAuthenticatedUser());
      dispatch(
        uiSlice.actions.showSophisticatedApplicationSubmittedModal(true)
      );
    }
  }, [dispatch, saveSophisticatedApplicationSuccess]);

  useEffect(() => {
    if (saveBankAccountInformationSuccess) {
      setConfirmedBankAccount(true);
    }
  }, [saveBankAccountInformationSuccess]);

  useEffect(() => {
    if (saveBidActivitySuccess) {
      dispatch(userSlice.actions.getAuthenticatedUser());
      dispatch(offerSlice.actions.loadOffers());
      dispatch(uiSlice.actions.showBidSubmittedModal(true));
    }
  }, [dispatch, saveBidActivitySuccess]);

  useEffect(() => {
    if (bidLink && !confirmedBankAccount) {
      dispatch(uiSlice.actions.showBankAccountModal(true));
    }
  }, [bidLink, confirmedBankAccount, dispatch]);

  const handleBidNow = () => {
    if (selectedOffer && (authenticatedUser?.verificationStatus === "Verified" ||
       (client?.settings?.workflow?.enableOnlineAccountRegistrationForm && onlineAccountRegistrationStatus === 2)
    )) {
      if (client?.settings?.workflow?.enableBankAccountConfirmation)
      {
        setConfirmedBankAccount(
          authenticatedUser?.bankAccountNumberLastDigits !== null
        );
      }
      dispatch(offerSlice.actions.loadBidLink({ offerId: selectedOffer.id }));
    } else {
      if (client?.settings?.workflow?.enableOnlineAccountRegistrationForm) {
        window.open(onlineAccountRegistrationUrl, "_blank");
      }
      else {
        dispatch(uiSlice.actions.showIdentityDocumentModal(true));
      }
    }
  };

  const handleOfferSelect = (offer: Offer) => {
    setSelectedOffer(offer);
  };

  const columns: any = getColumns(
    width,
    authenticatedUser,
    client,
    handleOfferSelect
  );

  const [selectedOffer, setSelectedOffer] = useState<Offer | undefined>(
    undefined
  );

  if (offersLoading)
    return (
      <SkeletonWrapper>
        <Skeleton
          style={{ marginBottom: 5, lineHeight: "35px", borderRadius: 0 }}
          count={5}
        />
      </SkeletonWrapper>
    );

  if (!offers) {
    return <NoOffersWarning>No Offers Available</NoOffersWarning>;
  }

  const handleBidSumbittedWindowMessageDisplay = () => {
    if (selectedOffer)
      dispatch(
        offerSlice.actions.saveBidActivity({ offerId: selectedOffer.id })
      );
    setSelectedOffer(undefined);
    dispatch(offerSlice.actions.clearBidLink());
  };

  const handleSophisticatedApplicationSubmitted = () => {
    dispatch(userSlice.actions.saveSophisticatedApplication());
  };

  return (
    <GridWrapper $width={width}>
      <StyledReactDataGrid
        rowClassName={(row: any) => {
          const closureInformation = GetClosureInformation(row.data.closesOn);
          if (closureInformation.expired) return "disabled";
          return "";
        }}
        $width={width}
        resizable={false}
        allowUnsort={false}
        defaultSortInfo={{ name: "closesOn", dir: 1 }}
        showColumnMenuTool={false}
        enableSelection={false}
        showHoverRows={false}
        showZebraRows={false}
        showCellBorders="horizontal"
        idProperty="id"
        columns={columns}
        reorderColumns={false}
        activeIndex={-1}
        rowHeight={88}
        dataSource={offers}
      />
      <GridFooter />
      {selectedOffer &&
        !bidLink &&
        !identityDocumentModalVisible &&
        !identityDocumentSubmittedModalVisible &&
        !bankAccountModalVisible && (
          <OfferViewModal
            offer={selectedOffer}
            onClose={() => setSelectedOffer(undefined)}
            onBidNow={handleBidNow}
          />
        )}
      {identityDocumentModalVisible && (
        <IdentityDocumentModal
          onClose={() =>
            dispatch(uiSlice.actions.showIdentityDocumentModal(false))
          }
        />
      )}
      <ConfirmationModal
        visible={identityDocumentSubmittedModalVisible}
        title="Identity Verified"
        onClose={() =>
          dispatch(uiSlice.actions.showIdentityDocumentSubmittedModal(false))
        }
      >
        Your ID has been successfully verified
      </ConfirmationModal>
      {!identityDocumentModalVisible && bidLink && confirmedBankAccount && (
        <IFrameModal
          windowMessageType={WINDOW_MESSAGE_TYPES.bidSubmitted}
          windowMessageCallback={handleBidSumbittedWindowMessageDisplay}
          title="Bid Now"
          url={bidLink}
          onClose={() => dispatch(offerSlice.actions.clearBidLink())}
        />
      )}
      <BidSubmittedConfirmationModal />
      <ConfirmationModal
        visible={sophisticatedApplicationSubmittedModalVisible}
        title="Sophisticated Application Submitted"
        onClose={() =>
          dispatch(
            uiSlice.actions.showSophisticatedApplicationSubmittedModal(false)
          )
        }
      >
        We have received your request to apply as a sophisticated investor.
        <br />
        You will be contacted by email once your application has been approved
        or if there is any additional information required for the review
        process.
      </ConfirmationModal>
      {sophisticatedApplicationLink && (
        <IFrameModal
          windowMessageType={
            WINDOW_MESSAGE_TYPES.SophisticatedApplicationSubmitted
          }
          windowMessageCallback={handleSophisticatedApplicationSubmitted}
          title="Sophisticated Application"
          url={sophisticatedApplicationLink}
          onClose={() =>
            dispatch(userSlice.actions.clearSophisticatedApplicationLink())
          }
        />
      )}
      {/* {onlineAccountRegistrationFormLink && (
        <IFrameModal
          windowMessageType={
            WINDOW_MESSAGE_TYPES.OnlineAccountRegistrationFormSubmitted
          }
          windowMessageCallback={handleSophisticatedApplicationSubmitted}
          title="Online Account Registration Application"
          url={onlineAccountRegistrationFormLink}
          onClose={() =>
            dispatch(userSlice.actions.clearOnlineAccountRegistrationLink())
          }
        />
      )} */}
    </GridWrapper>
  );
};

export default memo(OffersGrid);
