import { memo, useEffect } from "react";
import Modal from "../modal/Modal";
import { useAppSelector } from "hooks";
import { RootState } from "store";
import { bankAccountModalVisibleSelector } from "selectors/uiSelectors";
import { uiSlice } from "reducers/uiSlice";
import { useAppDispatch } from "hooks";
import { SaveBankAccountInformation } from "types/saveBankAccountInformation";
import { userSlice } from "reducers/userSlice";
import ModalNotice from "components/modalNotice/ModalNotice";
import {
  saveBankAccountInformationErrorSelector,
  saveBankAccountInformationSuccessSelector,
  savingBankAccountInformationSelector,
} from "selectors/userSelectors";
import { offerSlice } from "reducers/offerSlice";
import BankAccountForm from "components/bankAccountForm/BankAccountForm";

const BankAccountModal = () => {
  const dispatch = useAppDispatch();

  const savingBankAccountInformation = useAppSelector((state: RootState) =>
    savingBankAccountInformationSelector(state)
  );

  const saveBankAccountInformationSuccess = useAppSelector((state: RootState) =>
    saveBankAccountInformationSuccessSelector(state)
  );

  const saveBankAccountInformationError = useAppSelector((state: RootState) =>
    saveBankAccountInformationErrorSelector(state)
  );

  const bankAccountModalVisible = useAppSelector((state: RootState) =>
    bankAccountModalVisibleSelector(state)
  );

  const handleCancel = () => {
    dispatch(offerSlice.actions.clearBidLink());
    dispatch(uiSlice.actions.showBankAccountModal(false));
  };

  const handleSubmit = (values: any) => {
    const saveBankAccountInformation: SaveBankAccountInformation = {
      ...values,
      bsb: values.bsb.replace(/-|_/gi, ""),
      accountNumber: values.accountNumber.replace(/_/gi, ""),
    };
    dispatch(
      userSlice.actions.saveBankAccountInformation(saveBankAccountInformation)
    );
  };

  useEffect(() => {
    if (bankAccountModalVisible && saveBankAccountInformationSuccess) {
      dispatch(userSlice.actions.getAuthenticatedUser());
      dispatch(uiSlice.actions.showBankAccountModal(false));
    }
  }, [bankAccountModalVisible, dispatch, saveBankAccountInformationSuccess]);

  return (
    <Modal
      title="Your Bank Account Details"
      onCancel={handleCancel}
      visible={bankAccountModalVisible}
    >
      <ModalNotice className="mb-2" align="start">
        <b style={{ fontSize: "1rem", fontWeight: 500 }}>
          Please fill in your bank account details:
        </b>
      </ModalNotice>
      <ModalNotice className="mb-4" align="start">
        In order to refund the scale back/decline/reject deals to your nominated
        bank account.
      </ModalNotice>
      <BankAccountForm
        className="px-4"
        handleSubmit={handleSubmit}
        error={saveBankAccountInformationError}
        busy={savingBankAccountInformation}
      />
    </Modal>
  );
};

export default memo(BankAccountModal);
